import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useGradesQuery() {
  const oneHour = 1000 * 60 * 60;

  const {
    data: grades,
    isLoading: isGradesLoading,
    isError: gradesError,
  } = useQuery([QUERY_KEYS.GRADES], client.getGrades, {
    staleTime: oneHour * 4,
  });

  return { grades, isGradesLoading, gradesError };
}
