import { useMutation } from "@tanstack/react-query";

import api from "../api";

const client = api();

export default function useForgotPassword() {
  const {
    mutate: forgotPassword,
    isLoading,
    isSuccess,
    error,
  } = useMutation((email) => client.forgotPassword(email));

  return { forgotPassword, isLoading, isSuccess, error };
}
