import React from "react";
import { StarIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

export default function Rating({ value = 0, max = 5, className = "" }) {
  // Ensure the max prop is at least 1
  const totalStars = Math.max(1, max);

  // Render stars based on value and max
  const stars = Array.from({ length: totalStars }, (_, index) => (
    <StarIcon
      key={index}
      className={twMerge(
        "h-4 w-4 rotate-12 shrink-0",
        index < value ? "text-yellow" : "text-gray-300",
      )}
      fill={index < value ? "#FFE700" : "none"}
    />
  ));

  return (
    <div className={twMerge("flex flex-row flex-wrap space-x-1", className)}>
      {stars}
    </div>
  );
}

Rating.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
};
