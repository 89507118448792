import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useSchoolsFilterQuery(schoolName, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: schools,
    isInitialLoading: isSchoolsLoading,
    isError: schoolsError,
  } = useQuery(
    [QUERY_KEYS.SCHOOLS, schoolName],
    () => client.getSchools(schoolName),
    {
      staleTime: oneHour * 24,
      enabled: !!schoolName,
      ...options,
    },
  );

  return { schools, schoolsError, isSchoolsLoading };
}
