export default ({ post, patch, checkStatus, toJSON }) => ({
  createActivitySession: (body) =>
    post("universe/v1/activity_sessions", { body })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  updateActivitySession: (id, body) =>
    patch(`universe/v1/activity_sessions/${id}`, { body })
      .then((response) =>
        checkStatus({
          response,
          method: "PATCH",
          endpoint: "universe/v1/activity_sessions/:activity_id",
        }),
      )
      .then(toJSON),
});
