import { camelizeKeys } from "humps";
import { omitBy, isUndefined, isEmpty } from "lodash";
import {
  PAGES,
  PAGES_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

function replaceHyphensWithSpaces(str) {
  return typeof str === "string" ? str.replace(/-/g, " ") : undefined;
}

export const getAnalyticsParams = (params = {}) => {
  const camelizedParams = camelizeKeys(params);
  const {
    sourceElement,
    sourceName,
    sourcePage,
    sourcePageType,
  } = camelizedParams;
  if (!sourceElement && !sourceName && !sourcePage && !sourcePageType) {
    return {
      sourceElement: "direct",
      sourceName: "direct",
      sourcePage: "direct",
      sourcePageType: "direct",
    };
  }
  return omitBy(
    {
      sourceElement,
      sourceName,
      sourcePage,
      sourcePageType,
    },
    isUndefined,
  );
};

export function getSourcePageAndSourcePageTypeFromReferrer(path, params = {}) {
  const { identity } = params;
  const pageSlug = identity ? replaceHyphensWithSpaces(identity[1]) : undefined;

  let sourcePage = undefined;
  let sourcePageType = undefined;

  switch (path) {
    case "/":
      sourcePage = PAGES.HOME;
      sourcePageType = PAGES.HOME;
      break;
    case "/videos/[...identity]":
      sourcePage = `${pageSlug} detail page`;
      sourcePageType = PAGES.VIDEO_DETAIL;
      break;
    case "/activities/[...identity]":
      sourcePage = `${pageSlug} detail page`;
      sourcePageType = PAGES.PRINTABLE_DETAIL;
      break;
    case "/tags/[...identity]":
      sourcePage = params.isPrimaryContentTag
        ? `${pageSlug} listing page`
        : `${pageSlug} landing page`;
      sourcePageType = params.isPrimaryContentTag
        ? PAGES_TYPES.LISTING
        : PAGES_TYPES.LANDING;
      break;
    case "/games/[...identity]":
      sourcePage = `${pageSlug} detail page`;
      sourcePageType = PAGES.GAME_DETAIL;
      break;
    case "/account-settings":
      sourcePage = PAGES.ACCOUNT_SETTINGS;
      sourcePageType = PAGES.ACCOUNT_SETTINGS;
      break;
    case "/login":
      sourcePage = PAGES.LOGIN;
      sourcePageType = PAGES.LOGIN;
      break;
    case "/registration":
      sourcePage = PAGES.REGISTRATION;
      sourcePageType = PAGES.REGISTRATION;
      break;
    case "/newsletter-signup":
      sourcePage = PAGES.NEWSLETTER_SIGNUP;
      sourcePageType = PAGES.NEWSLETTER_SIGNUP;
      break;
    case "/search":
      sourcePage = PAGES.SEARCH;
      sourcePageType = PAGES.SEARCH;
      break;
    case "/curriculum/[id]":
      sourcePage = PAGES.CURRICULUM_COMPETENCIES;
      sourcePageType = PAGES.CURRICULUM_COMPETENCIES;
      break;
    case "/curriculum/[id]/[competency]":
      sourcePage = PAGES.CURRICULUM_ELEMENT;
      sourcePageType = PAGES.CURRICULUM_ELEMENT;
      break;
    case "/trophies":
      sourcePage = PAGES.TROPHIES;
      sourcePageType = PAGES.TROPHIES;
      break;
    default:
      break;
  }

  return omitBy({ sourcePage, sourcePageType }, isUndefined);
}

export function getUtmParamsFromQueryParams(params = {}) {
  const { utmSource, utmMedium, utmCampaign } = camelizeKeys(params);
  return omitBy({ utmSource, utmMedium, utmCampaign }, isEmpty);
}
