import useChampion from "./useChampion";
import useProfile from "./useProfile";
import { useUser } from "../contexts/user";

import { CHAMP_NAMES } from "../constants";

const LOGGED_OUT_USER_LEVEL = 1;
const LOGGED_OUT_USER_MAX_POINTS = 3;

export default function useCurrentChampion() {
  const { user } = useUser();
  const { profile } = useProfile();
  const defaultChampion = useChampion(CHAMP_NAMES.MC_PUFFERSON);

  // Backend returns different data structure for champions endpoint vs profile endpoint
  // A workaround is to update the defaultChampion shape to match the profile shape
  // This is a temporary fix until the backend is updated to return the same shape and it might break

  const defaultChampionWithProfileShape = defaultChampion
    ? {
        champId: defaultChampion.id,
        slug: defaultChampion.slug,
        name: defaultChampion.name,
        graduated: false,
        level: LOGGED_OUT_USER_LEVEL,
        levelPercentComplete: 0,
        pointsPerLevel: LOGGED_OUT_USER_MAX_POINTS,
        messages: defaultChampion.messages,
        images: {
          headshot: defaultChampion.images.phase1.headshot,
          full: defaultChampion.images.phase1.full,
        },
      }
    : undefined;

  return user.isLoggedIn
    ? profile?.currentClassroomChamp
    : defaultChampionWithProfileShape;
}
