import Router from "next/router";
import {
  generatePath,
  isLocalURL,
  concatenateQueryParams,
  isSubdomain,
} from "@gonoodle/gn-universe-utils";

import {
  ROUTE_PATHS,
  SPOTLIGHT_TYPES,
  ROUTE_PREFIX,
  CONTENT_TYPES,
} from "../constants";

export const generateSpotlightPathByType = (destinationType, params = {}) => {
  const DESTINATION = {
    [SPOTLIGHT_TYPES.VIDEO]: ROUTE_PATHS.VIDEOS,
    [SPOTLIGHT_TYPES.ACTIVITY]: ROUTE_PATHS.ACTIVITIES,
    [SPOTLIGHT_TYPES.TAG]: ROUTE_PATHS.TAGS,
  };

  if (DESTINATION[destinationType]) {
    const path = generatePath(DESTINATION[destinationType], params);
    return path;
  }
  return null;
};

export const pushExternal = (
  href,
  linkParams,
  as,
  options = { shallow: false },
) => {
  if (
    !isLocalURL(href) &&
    !isSubdomain(process.env.NEXT_PUBLIC_WEB_URL, href)
  ) {
    Router.push(
      concatenateQueryParams(`/${ROUTE_PREFIX.EXTERNAL_LINK_DISCLAIMER}`, {
        ...linkParams,
        href,
      }),
      as,
      options,
    );
  } else {
    Router.push(href, as, options);
  }
};

export function getRoutePrefixByContentType(contentType) {
  switch (contentType) {
    case CONTENT_TYPES.VIDEO:
      return `/${ROUTE_PREFIX.VIDEOS}`;
    case CONTENT_TYPES.ACTIVITY:
      return `/${ROUTE_PREFIX.ACTIVITIES}`;
    case CONTENT_TYPES.TOPIC:
      return `/${ROUTE_PREFIX.TAGS}`;
    case CONTENT_TYPES.CHANNEL:
      return `/${ROUTE_PREFIX.TAGS}`;
    case CONTENT_TYPES.TAG:
      return `/${ROUTE_PREFIX.TAGS}`;
    case CONTENT_TYPES.CUSTOM_LINK:
      return ``;
    default:
      return null;
  }
}
