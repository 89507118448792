/* eslint-disable import/prefer-default-export */
import React from "react";
import PropTypes from "prop-types";

export function Link({ as, children, href, className, ...rest }) {
  const Wrapper = as;
  if (Wrapper) {
    return (
      <Wrapper href={href} {...rest}>
        <a href={href} className={className}>
          {children}
        </a>
      </Wrapper>
    );
  }
  return (
    <a href={href} className={className} {...rest}>
      {children}
    </a>
  );
}

Link.propTypes = {
  as: PropTypes.func,
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Link.defaultProps = {
  as: undefined,
  children: null,
  className: undefined,
};
