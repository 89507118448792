import useCompetenciesCompletionsQuery from "./useCompetenciesCompletionsQuery";

export default function useCompetencyCompletionQuery(competencyId) {
  const {
    competenciesCompletions,
    isCompetenciesCompletionsLoading: isCompetencyCompletionLoading,
    competenciesCompletionsError: competencyCompletionError,
  } = useCompetenciesCompletionsQuery([competencyId]);
  return {
    competencyCompletion: competenciesCompletions?.[0],
    isCompetencyCompletionLoading,
    competencyCompletionError,
  };
}
