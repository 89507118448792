export default ({ get, checkStatus, toJSON }) => ({
  getPrintablesDetails: (id) =>
    get(`universe/v1/printables/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/printables/:printable_id",
        }),
      )
      .then(toJSON),
  getNewestPrintables: () =>
    get("universe/v1/newest_printables")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
});
