import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { SplashScreen as SplashScreenComponent } from "@gonoodle/gn-universe-ui";
import { useAppConfig } from "../contexts/appConfig";
import { useSplashScreen, useImpression } from "../hooks";
import { IMPRESSION_TYPES } from "../constants";

const SplashScreen = ({ children }) => {
  const { splashScreen, onLoadComplete } = useSplashScreen();
  const { appConfig } = useAppConfig();
  const { adProductSplashScreen } = appConfig;
  const ref = useImpression({
    properties: {
      impressionType: IMPRESSION_TYPES.LOGO,
      adProductType: "splash_screen",
      partnerName: splashScreen?.partnerName,
      partnerId: splashScreen?.partnerId,
      adProductPartnerId: splashScreen?.id,
    },
    threshold: 0.5,
    enabled: !!splashScreen,
  });

  return (
    <>
      {!isEmpty(splashScreen) && adProductSplashScreen.visible ? (
        <SplashScreenComponent
          ref={ref}
          src={splashScreen?.images?.splashScreenImage}
          alt={splashScreen?.partnerName}
          onLoad={onLoadComplete}
          onError={onLoadComplete}
        />
      ) : (
        children
      )}
    </>
  );
};

SplashScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SplashScreen;
