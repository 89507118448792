import { inBrowser } from "@gonoodle/gn-universe-utils";

export default {
  API_BASEURL: inBrowser()
    ? process.env.NEXT_PUBLIC_API_BASEURL
    : process.env.API_BASEURL,
  WEB_URL: process.env.NEXT_PUBLIC_WEB_URL,
  UNIVERSE_WEB_SECRET_KEY: process.env.NEXT_PUBLIC_UNIVERSE_WEB_SECRET_KEY,
  ANALYTICS_API_KEY: process.env.NEXT_PUBLIC_ANALYTICS_API_KEY,
  RECAPTCHA_ENTERPRISE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_ENTERPRISE_KEY,
  BLOG_URL: process.env.NEXT_PUBLIC_BLOG_URL,
  EDUCATOR_URL: process.env.NEXT_PUBLIC_EDUCATOR_URL,
};
