import React from "react";
import PropTypes from "prop-types";
import * as PrimitiveTabs from "@radix-ui/react-tabs";
import { twMerge } from "tailwind-merge";

const missingProvider = "Component must be used within <Tabs.Root>";

export const TabsContext = React.createContext({
  get headless() {
    throw new Error(missingProvider);
  },
});

TabsContext.displayName = "TabsContext";

export function Root({
  defaultValue,
  value,
  onValueChange,
  headless = false,
  className,
  children,
}) {
  const providerValue = React.useMemo(() => ({ headless }), [headless]);

  return (
    <TabsContext.Provider value={providerValue}>
      <PrimitiveTabs.Root
        defaultValue={defaultValue}
        value={value}
        onValueChange={onValueChange}
        className={className}
      >
        {children}
      </PrimitiveTabs.Root>
    </TabsContext.Provider>
  );
}

export function List({ loop = true, className, children }) {
  return (
    <PrimitiveTabs.List loop={loop} className={className}>
      {children}
    </PrimitiveTabs.List>
  );
}

export function Trigger({ value, disabled = false, className, children }) {
  const { headless } = React.useContext(TabsContext);

  return (
    <PrimitiveTabs.Trigger
      value={value}
      disabled={disabled}
      className={
        headless
          ? className
          : twMerge(
              "text-white text-md font-bold px-[10px] py-[6px] border-b-2 border-transparent transition-colors group outline-none hover:text-mint data-[state=active]:hover:border-mint data-[state=active]:border-white",
              className,
            )
      }
    >
      {headless ? (
        children
      ) : (
        <span className="p-1 rounded ring-2 ring-transparent transition-all group-focus:ring-white group-focus:group-hover:ring-mint">
          {children}
        </span>
      )}
    </PrimitiveTabs.Trigger>
  );
}

export function Content({ value, className, children }) {
  return (
    <PrimitiveTabs.Content value={value} className={className}>
      {children}
    </PrimitiveTabs.Content>
  );
}

Root.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  headless: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

List.propTypes = {
  loop: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Trigger.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Content.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};
