import React from "react";

export default function useOnce(callback) {
  const called = React.useRef(false);

  const invokeCallback = React.useCallback(
    (...args) => {
      if (!called.current && typeof callback === "function") {
        called.current = true;
        callback(...args);
      }
    },
    [callback],
  );

  return invokeCallback;
}
