import React from "react";
import PropTypes from "prop-types";

export default function BlogHeroSection({
  description,
  name,
  authorTitle,
  mainImage,
  title,
}) {
  return (
    <div>
      <div className="flex my-md flex-wrap justify-center lg:flex-nowrap">
        <div className="lg:mr-md mx-sm mt-md lg:mt-0 self-center">
          <hr className="border border-md border-mint mb-md" />
          <p className="my-sm font-body">
            {description}{" "}
            <strong>
              ~ {name} {authorTitle ? `, ${authorTitle}` : ""}
            </strong>
          </p>
          <hr className="border border-md border-mint mt-md" />
        </div>
        <div className="lg:mr-sm flex-shrink-0 self-center order-first lg:order-none">
          {mainImage && (
            <div className="bg-mint h-44">
              <img
                className="border-sm border-mint transform translate-x-xs -translate-y-xs h-44 w-80 object-cover"
                src={mainImage}
                alt={title}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BlogHeroSection.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  authorTitle: PropTypes.string,
  mainImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

BlogHeroSection.defaultProps = {
  authorTitle: undefined,
};
