import React from "react";
import PropTypes from "prop-types";
import {
  usePreventScroll,
  useModal,
  useOverlay,
  FocusScope,
  OverlayContainer,
} from "react-aria";
import { useObjectRef } from "@react-aria/utils";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const Modal = React.forwardRef(function Modal(
  { isOpen, isDismissable, className, children, overlay, onClose },
  forwardedRef,
) {
  const ref = useObjectRef(forwardedRef);
  usePreventScroll();
  const { modalProps } = useModal();
  const { overlayProps, underlayProps } = useOverlay(
    {
      isOpen,
      isDismissable,
      isKeyboardDismissDisabled: isDismissable === false,
      onClose,
    },
    ref,
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
      exit={{ opacity: 0, transition: { duration: 0.2, ease: "easeIn" } }}
      className={twMerge(
        "fixed inset-0 z-50 flex items-center justify-center h-screen px-sm",
        className,
      )}
      {...underlayProps}
    >
      <div className="inset-0 bg-black opacity-75 absolute" />

      <FocusScope contain restoreFocus autoFocus>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 16 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.3, ease: "easeInOut" },
          }}
          exit={{
            opacity: 0,
            y: 16,
            transition: { duration: 0.2, ease: "easeIn" },
          }}
          {...overlayProps}
          {...modalProps}
          {...overlay}
        >
          {children}
        </motion.div>
      </FocusScope>
    </motion.div>
  );
});

const Root = React.forwardRef(function Root(
  { isOpen, isDismissable, className, children, overlay, onClose },
  forwardedRef,
) {
  return (
    <AnimatePresence>
      {isOpen ? (
        <OverlayContainer>
          <Modal
            className={className}
            isOpen={isOpen}
            isDismissable={isDismissable}
            overlay={overlay}
            onClose={onClose}
            ref={forwardedRef}
          >
            {children}
          </Modal>
        </OverlayContainer>
      ) : null}
    </AnimatePresence>
  );
});

Root.propTypes = {
  isOpen: PropTypes.bool,
  isDismissable: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  overlay: PropTypes.shape({}),
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  isDismissable: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  overlay: PropTypes.shape({}),
};

Modal.defaultProps = {
  isOpen: false,
  isDismissable: true,
  overlay: {},
  className: "",
  onClose: () => {},
};

Root.defaultProps = {
  isOpen: false,
  isDismissable: true,
  overlay: {},
  className: "",
  onClose: () => {},
};

export default Root;
