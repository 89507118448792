import React from "react";
import PropTypes from "prop-types";
import { useAssetsConfig } from "../../contexts/AssetsConfig";

const SplashScreen = React.forwardRef(({ src, alt, onLoad, onError }, ref) => {
  const { assetsUrl } = useAssetsConfig();

  return (
    <div className="bg-white h-screen w-screen flex justify-center items-center">
      <div className="flex flex-col w-screen-lg space-y-sm justify-center items-center animate-bounce">
        <div className="flex flex-col md:flex-row space-x-sm justify-center items-center md:items-start">
          <img
            className="block relative w-2xl"
            src={`${assetsUrl}/web_public/GoNoodleLogo_Registered_RGB.svg`}
            alt="GoNoodle logo"
          />
          <h1 className="gn-text font-bold">is powered in your community by</h1>
        </div>

        <div className="flex flex-col items-start">
          <img
            className="w-3xl"
            ref={ref}
            src={src}
            alt={alt}
            onLoad={onLoad}
            onError={onError}
          />
        </div>
      </div>
    </div>
  );
});

SplashScreen.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default SplashScreen;
