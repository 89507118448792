// credit: https://github.com/vercel/next.js/blob/34dcede6902fc1bc100a3a9d8874851c4bcd9977/packages/next/shared/lib/router/router.ts#L214

const basePath = process.env.BASE_PATH || "";

function pathNoQueryHash(path) {
  const queryIndex = path.indexOf("?");
  const hashIndex = path.indexOf("#");

  if (queryIndex > -1 || hashIndex > -1) {
    // eslint-disable-next-line no-param-reassign
    path = path.substring(0, queryIndex > -1 ? queryIndex : hashIndex);
  }
  return path;
}

function getLocationOrigin() {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
}

function hasBasePath(path) {
  // eslint-disable-next-line no-param-reassign
  path = pathNoQueryHash(path);
  return path === basePath || path.startsWith(`${basePath}/`);
}

/**
 * Detects whether a given url is routable by the Next.js router (browser only).
 */
export default function isLocalURL(url = "") {
  // prevent a hydration mismatch on href for url with anchor refs
  if (url.startsWith("/") || url.startsWith("#") || url.startsWith("?"))
    return true;
  try {
    // absolute urls can be local if they are on the same origin
    const locationOrigin = getLocationOrigin();
    const resolved = new URL(url, locationOrigin);
    return resolved.origin === locationOrigin && hasBasePath(resolved.pathname);
  } catch (_) {
    return false;
  }
}
