import Joi from "joi";
import { PAGES, PAGES_TYPES, CONTENT_TYPES, SECTIONS } from "../constants";

export const videoPlayed = Joi.object({
  player: Joi.string().valid("mux", "jw").required(),
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(
      "direct",
      "share",
      ...Object.values(PAGES),
      ...Object.values(PAGES_TYPES),
    )
    .required(),
  sourceElement: Joi.string().required(),
  contentType: Joi.string().valid(CONTENT_TYPES.VIDEO).required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  autoPlayed: Joi.boolean().required(),
  brainBreakSessionId: Joi.number().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const curriculumVideoPlayed = Joi.object({
  player: Joi.string().valid("mux", "jw").required(),
  contentType: Joi.string().valid(CONTENT_TYPES.VIDEO).required(),
  curriculumName: Joi.string().required(),
  competencyName: Joi.string().required(),
  chapterName: Joi.string().required(),
  episodeName: Joi.string().required(),
  elementName: Joi.string().required(),
  ageGroup: Joi.string().required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  brainBreakSessionId: Joi.number().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const curriculumActivityDownloaded = Joi.object({
  curriculumName: Joi.string().required(),
  contentType: Joi.string().valid(CONTENT_TYPES.ACTIVITY).required(),
  competencyName: Joi.string().required(),
  chapterName: Joi.string().required(),
  episodeName: Joi.string().required(),
  elementName: Joi.string().required(),
  ageGroup: Joi.string().required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const activityDownloaded = Joi.object({
  sourceName: Joi.string(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceElement: Joi.string(),
  contentType: Joi.string().valid(CONTENT_TYPES.ACTIVITY).required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const shared = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string().valid(SECTIONS.GOOGLE_CLASSROOM_SHARE_BUTTON),
  contentType: Joi.string()
    .valid(CONTENT_TYPES.VIDEO, CONTENT_TYPES.ACTIVITY)
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const addToFavorites = Joi.object({
  contentType: Joi.string()
    .valid(...Object.values(CONTENT_TYPES))
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceElement: Joi.string().valid(SECTIONS.FAVORITE_BUTTON),
  sourceName: Joi.string().valid(SECTIONS.FAVORITE_BUTTON),
  gradeId: Joi.number().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const removeFromFavorites = Joi.object({
  contentType: Joi.string()
    .valid(...Object.values(CONTENT_TYPES))
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceElement: Joi.string().valid(SECTIONS.FAVORITE_BUTTON),
  sourceName: Joi.string().valid(SECTIONS.FAVORITE_BUTTON),
  gradeId: Joi.number().required(),
});

export const competencyCompleted = Joi.object({
  competencyCompletionPercentage: Joi.number().required(),
  curriculumName: Joi.string().required(),
  competencyName: Joi.string().required(),
  ageGroup: Joi.string().required(),
  schoolId: Joi.number().allow(null),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const competencyStarted = Joi.object({
  competencyCompletionPercentage: Joi.number().required(),
  curriculumName: Joi.string().required(),
  competencyName: Joi.string().required(),
  chapterName: Joi.string().required(),
  episodeName: Joi.string().required(),
  elementName: Joi.string().required(),
  ageGroup: Joi.string().required(),
  schoolId: Joi.number().allow(null),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const elementMarkedCompleted = Joi.object({
  competencyCompletionPercentage: Joi.number().required(),
  curriculumName: Joi.string().required(),
  competencyName: Joi.string().required(),
  chapterName: Joi.string().required(),
  episodeName: Joi.string().required(),
  elementId: Joi.number().required(),
  elementName: Joi.string().required(),
  elementType: Joi.string().required(),
  ageGroup: Joi.string().required(),
  subElementIds: Joi.array().items(Joi.number()),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const gamePlayed = Joi.object({
  id: Joi.string().required(),
  contentType: Joi.string().valid(CONTENT_TYPES.GAME).required(),
  title: Joi.string().required(),
  levelName: Joi.string(),
  activitiesStarted: Joi.number(),
  activitiesCompleted: Joi.number(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string().required(),
  sourceElement: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});
