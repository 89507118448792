export default {
  HOME: "home page",
  VIDEO_DETAIL: "video detail page",
  PRINTABLE_DETAIL: "printable detail page",
  GAME_DETAIL: "game detail page",
  NEWSLETTER_SIGNUP: "newsletter signup page",
  LOGIN: "login page",
  REGISTRATION: "registration page",
  ACCOUNT_SETTINGS: "account settings page",
  SEARCH: "search page",
  EXTERNAL_LINK_DISCLAIMER: "external link disclaimer page",
  FAVORITES: "favorites page",
  PROFILE: "profile page",
  CURRICULUM_COMPETENCIES: "curriculum competencies page",
  CURRICULUM_ELEMENT: "curriculum element page",
  TROPHIES: "trophies page",
};
