export default ({ get, checkStatus, toJSON }) => ({
  getShelf: (id) =>
    get(`universe/v1/home_page_shelves/search?shelves_ids=[${id}]`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/home_page_shelves/search?:shelves_ids",
        }),
      )
      .then(toJSON),
  getRecentlyWatchedShelf: (id) =>
    get(
      `universe/v2/recently_watched_shelves/search?recently_watched_shelves_ids=${id}`,
    )
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint:
            "universe/v2/recently_watched_shelves/search?:recently_watched_shelves_ids",
        }),
      )
      .then(toJSON),
  getFavoritesShelf: (id) =>
    get(`universe/v2/favorites_shelves/search?favorites_shelves_ids=${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint:
            "universe/v2/favorites_shelves/search?:favorites_shelves_ids",
        }),
      )
      .then(toJSON),
  getDefaultShelf: (position) =>
    get(`universe/v1/home_page_shelves/default?position=${position}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/home_page_shelves/default?:position",
        }),
      )
      .then(toJSON),
  getCustomShelf: (id) =>
    get(`universe/v2/custom_shelves/search?custom_shelves_ids=${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v2/custom_shelves/search?:custom_shelves_ids",
        }),
      )
      .then(toJSON),
});
