import React from "react";
import PropTypes from "prop-types";
import { useAssetsConfig } from "../../contexts/AssetsConfig";

export default function MaintenancePage({ noticeText }) {
  const { assetsUrl } = useAssetsConfig();
  return (
    <div className="flex flex-col justify-between items-center w-full h-screen p-md">
      <div>
        <span className="sr-only">GoNoodle</span>
        <img
          style={{ width: "200px" }}
          className="block relative"
          src={`${assetsUrl}/web_public/GoNoodleLogo_Registered_RGB.svg`}
          alt="GoNoodle logo"
        />
      </div>
      <div className="flex flex-col items-center space-y-md w-full md:max-w-screen-md mx-auto">
        <div className="w-1/3 md:w-1/4">
          <img
            className="object-cover w-full h-auto"
            src={`${assetsUrl}/web_public/Nova_Steemsteen_4.png`}
            alt=""
          />
        </div>
        <div className="w-full text-center">
          <h2 className="gn-subtitle mb-md">{noticeText}</h2>
          <h2 className="gn-text">
            Please contact{" "}
            <a
              className="font-bold underline"
              href="mailto:support@gonoodle.com"
            >
              support@gonoodle.com
            </a>{" "}
            with any questions.
          </h2>
        </div>
      </div>
      <div>&nbsp;</div>
    </div>
  );
}
MaintenancePage.propTypes = {
  noticeText: PropTypes.string.isRequired,
};
