/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { useTextField } from "react-aria";
import { useComposedRefs } from "@gonoodle/gn-universe-utils";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const Textarea = React.forwardRef(function Textarea(
  {
    name,
    label,
    placeholder,
    description,
    errorMessage,
    value,
    required,
    disabled,
    defaultValue,
    maxLength,
    onBlur,
    onChange,
  },
  forwardedRef,
) {
  const ref = React.useRef();
  const composedRefs = useComposedRefs(ref, forwardedRef);
  const {
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
  } = useTextField(
    {
      name,
      label,
      placeholder,
      description,
      errorMessage,
      value,
      defaultValue,
      isDisabled: disabled,
      isRequired: required,
      onChange,
      onBlur,
      inputElementType: "textarea",
      maxLength,
    },
    ref,
  );
  const hasError = Boolean(errorMessage);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        {label ? (
          <label {...labelProps} className="mb-xs gn-text-sm text-gray-500">
            {label}
          </label>
        ) : null}

        {description ? (
          <span
            {...descriptionProps}
            className="mb-xs gn-text-sm text-gray-500"
          >
            {description}
          </span>
        ) : null}
      </div>
      <textarea
        ref={composedRefs}
        {...inputProps}
        className={`block w-full h-[84px] pl-sm gn-text rounded border-sm border-gray-300 shadow-lightSM
          ${
            hasError
              ? "text-danger placeholder-danger border-none ring ring-danger focus:ring focus:ring-danger"
              : "text-gray-900 placeholder-gray-500 focus:ring focus:ring-purple focus:outline-none focus:border-none"
          }
         `}
      />
      <div
        {...errorMessageProps}
        className={`mt-xs flex flex-row gap-xs ${!hasError && "hidden"}`}
      >
        <ExclamationCircleIcon
          className="h-input-icon text-danger flex-shrink-0"
          aria-hidden="true"
        />
        <div className="gn-text-sm text-danger">{errorMessage}</div>
      </div>
    </div>
  );
});

Textarea.displayName = "Textarea";

Textarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errorMessage: PropTypes.node,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

Textarea.defaultProps = {
  name: undefined,
  label: undefined,
  placeholder: undefined,
  errorMessage: undefined,
  value: "",
  description: undefined,
  required: undefined,
  disabled: undefined,
  defaultValue: undefined,
  maxLength: undefined,
  onBlur: undefined,
  onChange: undefined,
};

export default Textarea;
