import React from "react";

import { PlayerContext } from ".";

export default function usePlayer() {
  const context = React.useContext(PlayerContext);
  if (context === undefined) {
    throw new Error("You forgot to wrap your component in a Player");
  }

  return context;
}
