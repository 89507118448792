import Joi from "joi";

import {
  PAGES,
  PAGES_TYPES,
  SECTIONS,
  SECTIONS_TYPES,
  PROFILE_TYPES,
} from "../constants";

export const emailPreferencesChanged = Joi.object({
  newsletterSignup: Joi.boolean().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const profileEdited = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string().valid(SECTIONS.EDIT_PROFILE_BUTTON).required(),
  sourceElement: Joi.string().valid(SECTIONS_TYPES.PROFILE).required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
  gradeId: Joi.number(),
  name: Joi.string(),
  schoolId: Joi.any(),
  size: Joi.number(),
});

export const addProfileSelected = Joi.object({});

export const profileAbandoned = Joi.object({
  sourceName: Joi.string()
    .valid(SECTIONS.ADD_PROFILE_BUTTON, SECTIONS.EDIT_PROFILE_BUTTON)
    .required(),
  sourceElement: Joi.string().valid(SECTIONS_TYPES.PROFILE).required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const profileDeleted = Joi.object({
  profileType: Joi.string().required(),
});

export const profileCreated = Joi.object({
  sourcePage: Joi.string().valid(PAGES.PROFILE, PAGES.REGISTRATION).required(),
  sourcePageType: Joi.string()
    .valid(PAGES.PROFILE, PAGES.REGISTRATION)
    .required(),
  sourceName: Joi.string()
    .valid(
      "direct",
      SECTIONS.ADD_PROFILE_BUTTON,
      SECTIONS.CREATE_ACCOUNT_BUTTON,
      SECTIONS.ADD_CLASSROOM_BUTTON,
      SECTIONS_TYPES.NAV,
      SECTIONS_TYPES.CREATE_ACCOUNT,
      SECTIONS_TYPES.LOGIN,
      SECTIONS.CONNECT_TO_CLEVER,
    )
    .required(),
  sourceElement: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
  gradeId: Joi.number().required(),
  name: Joi.string().required(),
  schoolId: Joi.any(),
  size: Joi.number(),
  profileId: Joi.string(),
  profileType: Joi.string()
    .valid(PROFILE_TYPES.CLASSROOM, PROFILE_TYPES.KID_HOME)
    .required(),
});

export const cleverClassesSynced = Joi.object({});
