import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { QUERY_KEYS } from "../constants";
import api from "../api";
import useAgeGroup from "./useAgeGroup";

const client = api();

export default function useCompetency() {
  const router = useRouter();
  const ageGroup = useAgeGroup();
  const { competency: competencyID } = router.query;

  const { data: competency } = useQuery(
    [QUERY_KEYS.COMPETENCY, competencyID, ageGroup.id],
    () => client.getCompetency(competencyID),
  );

  return competency;
}
