import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export default function Skeleton({ className }) {
  return (
    <div
      className={twMerge(
        "animate-pulse rounded border-2 bg-gray-500 border-gray-500",
        className,
      )}
    />
  );
}

Skeleton.propTypes = {
  className: PropTypes.string,
};

Skeleton.defaultProps = {
  className: undefined,
};
