import React from "react";
import PropTypes from "prop-types";
import { Transition, Menu } from "@headlessui/react";

export function Root({ children, asChild, className }) {
  return (
    <Menu as={asChild} role="navigation" className={className}>
      {({ open }) =>
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            ...child.props,
            isOpen: open,
          }),
        )
      }
    </Menu>
  );
}

export function Trigger({ children, className, isOpen }) {
  return (
    <Menu.Button className={className}>
      {typeof children === "function" ? children(isOpen) : children}
    </Menu.Button>
  );
}

export function Item({ children, className, asChild, ...rest }) {
  return (
    <Menu.Item className={className} as={asChild} {...rest}>
      {children}
    </Menu.Item>
  );
}

export function List({ children, className, asChild, isOpen }) {
  return (
    <Transition
      show={isOpen}
      as={React.Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-md"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-md"
    >
      <Menu.Items as={asChild} className={`absolute z-30 ${className}`}>
        {children}
      </Menu.Items>
    </Transition>
  );
}

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  asChild: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  className: PropTypes.string,
};

Root.defaultProps = {
  asChild: "div",
  className: undefined,
};

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  asChild: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

List.defaultProps = {
  isOpen: false,
  asChild: "ul",
  className: undefined,
};

Trigger.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

Trigger.defaultProps = {
  className: undefined,
  isOpen: undefined,
};

Item.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  asChild: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
};

Item.defaultProps = {
  className: undefined,
  asChild: "li",
};
