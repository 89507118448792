import { useLocalstorageState } from "rooks";

import { TRANSMOGRIFIER } from "../constants";

const formatPoints = (points) => {
  const value = Number(points);

  if (!value || Number.isNaN(value) || value < 0) {
    return 0;
  }

  return value;
};

export default function useLoggedOutChampPoints() {
  const [points, setPoints] = useLocalstorageState(
    "gonoodle-logged-out-champ-points",
    0,
  );

  const currentPoints = formatPoints(points);

  return {
    loggedOutChampPoints: currentPoints,
    incrementLoggedOutChampPoints: (pointsEarned = 1) => {
      if (currentPoints < TRANSMOGRIFIER.LOGGED_OUT_USER_MAX_POINTS) {
        setPoints(currentPoints + pointsEarned);
      }
    },
    resetLoggedOutChampPoints: () => {
      setPoints(0);
    },
  };
}
