export default ({ post, checkStatus, toJSON }) => ({
  importCleverClassrooms: (school) =>
    post("universe/v1/users/import_clever_classrooms", {
      body: {
        schoolId: school.id,
      },
    })
      .then((response) => checkStatus({ response, method: "POST" }))
      .then(toJSON),
  disconnect: (provider) =>
    post("/universe/v1/oauth/disconnect", {
      body: {
        provider: `${provider}`,
      },
    }).then((response) => checkStatus({ response, method: "POST" })),
});
