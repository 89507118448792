import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useCompetencyQuery({ id, ageGroup }, options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: competency,
    isLoading: isCompetencyLoading,
    isError: competencyError,
  } = useQuery(
    [QUERY_KEYS.COMPETENCY, id, ageGroup],
    () => client.getCompetency(id),
    {
      staleTime: oneHour * 4,
      ...options,
    },
  );

  return { competency, isCompetencyLoading, competencyError };
}
