import useChampions from "./useChampions";

export default function useChampion(championName, championID) {
  const { champions = [] } = useChampions();
  if (championName) {
    return champions.find((champion) => champion.name === championName);
  }
  if (championID) {
    return champions.find((champion) => champion.id === championID);
  }
  return undefined;
}
