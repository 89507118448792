export default ({ get, checkStatus, toJSON }) => ({
  getHeroUnit: (id) =>
    get(`universe/v2/hero_units/search?hero_unit_ids=[${id}]`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v2/hero_units/search?:hero_unit_ids",
        }),
      )
      .then(toJSON),
});
