import { useEffect, useCallback, useRef } from "react";

import usePlayer from "./usePlayer";

export default function usePlaybackTimeInterval(
  callback,
  delay,
  options = { once: false },
) {
  const progress = useRef(0);
  const hasCalledOnce = useRef(false);
  const prevPosition = useRef(0);
  const prevDelay = useRef();
  const savedCallback = useRef();
  const player = usePlayer();

  const reset = useCallback(() => {
    prevPosition.current = 0;
    progress.current = 0;
    hasCalledOnce.current = false;
  }, []);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Remember the latest delay
  useEffect(() => {
    prevDelay.current = delay;
  }, [delay]);

  useEffect(() => {
    let unsubscribe = () => {};

    unsubscribe = player.onTimeUpdate(({ position }) => {
      if (player.getState() === "playing" && delay !== null) {
        // only count when video is playing and passing a null delay will pause the counting.
        const deltaTime = position - prevPosition.current;
        // more info at https://github.com/HealthTeacher/gn-family-web/pull/973
        if (deltaTime > 2) {
          // JW Player appears to sporadically trigger the `time` event prior to `seek`,
          // which can cause large deltas to come through. Until this is fixed, we
          // presume deltas larger than 2 are not "normal" playback progress. so they are skipped.
        } else if (deltaTime > 0) {
          // normal time progress
          progress.current += deltaTime;
        }
        prevPosition.current = position;

        if (
          progress.current >= delay &&
          ((options.once && !hasCalledOnce.current) || !options.once)
        ) {
          savedCallback.current(progress.current);
          hasCalledOnce.current = true;
          progress.current = 0;
        }
      }
    });

    return () => {
      unsubscribe();

      if (delay !== prevDelay.current) {
        // user of the hook has changed the delay value so we need to start counting from the beginning.
        reset();
      }
    };
  }, [delay, player, reset, options.once]);

  const flush = useCallback(() => {
    savedCallback.current(progress.current);
    reset();
  }, [reset]);

  return { flush, reset };
}
