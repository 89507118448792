import React from "react";
import PropTypes from "prop-types";

const SkeletonLoader = React.forwardRef(({ mode, size }, ref) => {
  const isDark = mode === "dark";
  const isShort = size === "short";
  return (
    <div
      ref={ref}
      className={`
            flex justify-center items-center rounded border-2 bg-gray-500
            ${isShort ? "aspect-w-16 aspect-h-9" : "aspect-w-7 aspect-h-9"}
            ${
              isDark
                ? "border-gray-500 animate-pulse"
                : "border-gray-900 animate-pulse"
            }
        `}
    >
      {/* <SparklesIcon className="w-md" /> */}
    </div>
  );
});

export default SkeletonLoader;

SkeletonLoader.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  size: PropTypes.oneOf(["short", "tall"]),
};

SkeletonLoader.defaultProps = {
  mode: "dark",
  size: "short",
};
