import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useGameDetailsQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: gameDetails,
    isLoading: isGameDetailsLoading,
    isError: gameDetailsError,
  } = useQuery(
    [QUERY_KEYS.GAME_DETAILS, slug],
    () => client.getGameDetails(slug),
    {
      ...options,
      staleTime: oneHour * 4,
    },
  );

  return { gameDetails, isGameDetailsLoading, gameDetailsError };
}
