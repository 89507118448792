import Joi from "joi";
import {
  CONTENT_TYPES,
  PAGES,
  PAGES_TYPES,
  SECTIONS_TYPES,
  SECTIONS,
} from "../constants";

export const userIdentified = Joi.object({
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(
      "direct",
      "share",
      ...Object.values(PAGES),
      ...Object.values(PAGES_TYPES),
    )
    .required(),
  sourceElement: Joi.string().required(),
  contentType: Joi.string()
    .valid(...Object.values(CONTENT_TYPES))
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const userIdentificationModalViewed = Joi.object({
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(
      "direct",
      "share",
      ...Object.values(PAGES),
      ...Object.values(PAGES_TYPES),
    )
    .required(),
  sourceElement: Joi.string().required(),
  contentType: Joi.string()
    .valid(...Object.values(CONTENT_TYPES))
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const loginSucceeded = Joi.object({
  sourceElement: Joi.string().valid(
    SECTIONS.LOGIN_WITH_CLEVER_BUTTON,
    SECTIONS.LOGIN_WITH_CLEVER_LIBRARY,
    SECTIONS.REGISTER_WITH_CLEVER_BUTTON,
    SECTIONS.LOGIN_WITH_GOOGLE_BUTTON,
    SECTIONS.LOGIN_WITH_GOOGLE_LIBRARY,
    SECTIONS.REGISTER_WITH_GOOGLE_BUTTON,
  ),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const loginFailed = Joi.object({
  sourceElement: Joi.string().valid(
    SECTIONS.LOGIN_WITH_CLEVER_BUTTON,
    SECTIONS.LOGIN_WITH_CLEVER_LIBRARY,
    SECTIONS.LOGIN_WITH_GOOGLE_BUTTON,
    SECTIONS.LOGIN_WITH_GOOGLE_LIBRARY,
  ),
  failureReason: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const profileSelected = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string().valid(
    "direct",
    "share",
    SECTIONS_TYPES.NAV,
    SECTIONS_TYPES.LOGIN,
    SECTIONS_TYPES.CREATE_ACCOUNT,
  ),
  sourceElement: Joi.string().valid(
    "direct",
    "share",
    SECTIONS_TYPES.NAV,
    SECTIONS_TYPES.CLEVER,
    SECTIONS_TYPES.GOOGLE,
    SECTIONS_TYPES.LOGIN,
  ),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const logout = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string().valid(SECTIONS.LOGOUT_BUTTON).required(),
  sourceElement: Joi.string().valid(SECTIONS_TYPES.NAV).required(),
});

export const cleverSignUpSubmission = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.CLEVER).required(),
});

export const googleSignUpSubmission = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.GOOGLE).required(),
});

export const cleverAccountConnected = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.CLEVER).required(),
});

export const googleAccountConnected = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.GOOGLE).required(),
});

export const cleverSignUpStarted = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.CREATE_ACCOUNT).required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const googleSignUpStarted = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.CREATE_ACCOUNT).required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});
