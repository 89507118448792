export default {
  DURATION: "duration",
  ACTIVITY_TYPES: "activityTypes",
  ENERGY_LEVELS: "energyLevels",
  GRADE_LEVELS: "gradeLevels",
  CHANNELS: "channels",
  FEATURED_CHANNEL: "featuredChannel",
  CHARACTERS: "characters",
  CHAMPIONS: "champions",
};
