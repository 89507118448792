import isEmpty from "lodash/isEmpty";
import { useState, useEffect } from "react";

import { AD_PRODUCTS_TYPES } from "../constants";
import { useAppConfig } from "../contexts/appConfig";
import useRotatedAdProductOfTypeQuery from "./useRotatedAdProductOfTypeQuery";

export default function useSplashScreen() {
  const { appConfig, setSplashScreenVisibility } = useAppConfig();
  const { adProductSplashScreen } = appConfig;
  const [splashScreenRotated, setSplashScreenRotated] = useState(false);
  const { adProduct: splashScreen } = useRotatedAdProductOfTypeQuery(
    AD_PRODUCTS_TYPES.AD_PRODUCT_SPLASH_SCREEN,
  );

  useEffect(() => {
    if (!splashScreenRotated && !isEmpty(splashScreen)) {
      setSplashScreenRotated(true);
    }

    if (
      splashScreenRotated &&
      !isEmpty(splashScreen) &&
      !adProductSplashScreen.seen &&
      !adProductSplashScreen.visible
    ) {
      setSplashScreenVisibility(true);
    }
  }, [
    adProductSplashScreen.seen,
    adProductSplashScreen.visible,
    setSplashScreenVisibility,
    splashScreen,
    splashScreenRotated,
  ]);

  const onLoadComplete = () => {
    setTimeout(
      () => adProductSplashScreen.visible && setSplashScreenVisibility(false),
      5000,
    );
  };

  return { splashScreen, onLoadComplete };
}
