import React from "react";
import { useRouter } from "next/router";

import useProfile from "./useProfile";

export default function useAgeGroup() {
  const router = useRouter();
  const { profile } = useProfile();
  const { id: curriculumId } = router.query;

  const ageGroup = React.useMemo(() => {
    if (!profile || !curriculumId) {
      return null;
    }

    const curriculumSettings = profile.curriculumSettings.find(
      (settings) => String(settings.curriculumId) === curriculumId,
    );

    return curriculumSettings?.ageGroup;
  }, [profile, curriculumId]);

  return ageGroup;
}
