function getSecondLevelDomain(url) {
  const { hostname } = new URL(url);
  const parts = hostname.split(".");
  if (parts.length > 1) {
    return parts.slice(-2)[0];
  }
  return parts[0];
}

export default function isSubdomain(baseUrl, url) {
  try {
    const baseSLD = getSecondLevelDomain(baseUrl);
    const urlSLD = getSecondLevelDomain(url);

    // Check if SLDs match (e.g. gonoodle.com, gonoodle.pizza and supernoodle.gonoodle.com) will all match
    return baseSLD === urlSLD;
  } catch (e) {
    return false; // Unable to determine due to invalid URL
  }
}
