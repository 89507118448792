import React from "react";

import { useAssetsConfig } from "../../contexts/AssetsConfig";

export default function ConfirmNewsletterSubscription() {
  const { assetsUrl } = useAssetsConfig();
  return (
    <div className="gn-page">
      <div className="container mx-auto px-md md:px-lg lg:px-2xl xl:px-3xl py-lg md:py-xl">
        <h1 className="gn-page-header-sm">
          Thanks for signing up for the GoNoodle newsletter.
        </h1>
        <img
          src={`${assetsUrl}/web_public/Gif-sans-games.gif`}
          alt="Thanks for signing up for the GoNoodle newsletter"
          className="w-full md:w-3/4 mr-auto my-lg"
        />
      </div>
    </div>
  );
}
