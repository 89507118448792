/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import Image from "../Image";
import { ImageSourcesType } from "../Image/types";

const getDays = (sponsorExpDate) =>
  new Date(sponsorExpDate).getTime() / (1000 * 60 * 60 * 24) -
  new Date().getTime() / (1000 * 60 * 60 * 24);

const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};

function secondsToTime(dur) {
  let hrs = Math.floor(dur / 3600);
  let min = Math.floor((dur - hrs * 3600) / 60);
  let sec = dur - hrs * 3600 - min * 60;

  if (hrs > 0) {
    hrs = `${hrs}:`;

    if (min < 10) {
      min = `0${min}`;
    }
  } else {
    hrs = "";
  }

  if (sec < 10) {
    sec = `0${sec}`;
  }

  return `${hrs}${min}:${sec}`;
}

const tagClasses = "p-xs text-white gn-text-xs rounded";

const VideoCard = React.forwardRef(
  (
    {
      mode,
      title,
      subtitle,
      description,
      images,
      sponsorExpDate,
      duration,
      handleClick,
      actions,
      testId,
    },
    ref,
  ) => {
    const daysLeft = React.useMemo(() => getDays(sponsorExpDate), [
      sponsorExpDate,
    ]);
    const isPast = React.useMemo(
      () => dateInPast(new Date(), new Date(sponsorExpDate)),
      [sponsorExpDate],
    );
    const isDark = mode === "dark";

    return (
      <div
        data-testid={testId}
        ref={ref}
        className="relative"
        onClick={handleClick}
      >
        {/* Thumbnail Container */}
        <div
          className={`
          relative aspect-w-16 aspect-h-9 bg-gray-700 rounded group overflow-hidden transition-shadow
          ${isDark ? "shadow-darkSM" : "shadow-lightSM"}
        `}
        >
          {/* Thumbnail */}
          <Image
            className="absolute inset-0 object-contain transform transition duration-200 group-hover:scale-110"
            sources={images.tile}
          />

          {/* Sponsorship & Duration */}
          <div
            className={`absolute flex ${
              sponsorExpDate && isPast ? "justify-between" : "justify-end"
            }`}
          >
            {sponsorExpDate ? (
              isPast ? (
                <span
                  className={`${tagClasses} bg-purple self-end mb-xs ml-xs truncate mr-sm transition-opacity`}
                  data-testid="video-card-sponsorship"
                >
                  {daysLeft > 14
                    ? "Sponsored"
                    : `Sponsored - ${Math.ceil(daysLeft)} day${
                        Math.ceil(daysLeft) > 1 ? "s" : ""
                      } left`}
                </span>
              ) : null
            ) : null}
            <span
              className={`${tagClasses} bg-black bg-opacity-60 self-end mb-xs mr-xs transition-all`}
              data-testid="video-card-duration"
            >
              {secondsToTime(duration)}
            </span>
          </div>
        </div>

        <div className="flex flex-row mt-xs space-x-sm justify-between items-start">
          <div>
            <h3
              className={`gn-text-sm mt-xs line-clamp-3 ${
                isDark ? "text-white" : "text-900"
              }`}
            >
              {title}
            </h3>
            {subtitle && (
              <p
                data-testid="video-card-subtitle"
                className="gn-text-xs text-gray-500"
              >
                {subtitle}
              </p>
            )}
          </div>

          {actions}
        </div>

        {/* Mobile Content */}
        {description && (
          <div data-testid="video-card-description">
            <p className="gn-text-xs mt-xs text-gray-500 line-clamp-4">
              {description}
            </p>
          </div>
        )}
      </div>
    );
  },
);

export default VideoCard;

VideoCard.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.shape({ tile: ImageSourcesType }).isRequired,
  sponsorExpDate: PropTypes.string,
  duration: PropTypes.number.isRequired,
  handleClick: PropTypes.func,
  actions: PropTypes.node,
  testId: PropTypes.string,
};

VideoCard.defaultProps = {
  mode: "dark",
  subtitle: undefined,
  description: undefined,
  sponsorExpDate: undefined,
  handleClick: () => {},
  actions: undefined,
  testId: undefined,
};
