export default ({ get, post, toJSON, checkStatus }) => ({
  updateOnSiteMessageStatus: (messagesStatus) =>
    post("universe/v1/on_site_message_tracks", {
      body: {
        onSiteMessageTracks: messagesStatus,
      },
    }).then((response) => checkStatus({ response, method: "POST" })),
  getOnSiteMessagesV2: (contentType, contentId) =>
    get(
      `universe/v2/on_site_messages?content_type=${contentType}&content_id=${contentId}`,
    )
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
});
