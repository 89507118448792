import api from "../../../api";

const client = api();

export default function sendEvent(event, properties) {
  return client.trackAnalyticsEvents({
    event,
    properties,
  });
}
