import { decamelizeKeys } from "humps";
import { SEARCH_FILTER_TYPES } from "../constants";

export default ({ get, checkStatus, toJSON }) => ({
  search: ({ query, page, pageSize, filters = {}, fuzzySearchTerm }) => {
    let filtersQuery = "";
    const revisedActiveSearchFilters = {};

    Object.keys(filters).forEach((filterName) => {
      if (Object.values(SEARCH_FILTER_TYPES).includes(filterName)) {
        revisedActiveSearchFilters[filterName] = filters[filterName];
      }
    });

    if (revisedActiveSearchFilters.champions)
      Object.assign(revisedActiveSearchFilters, {
        champs: revisedActiveSearchFilters.champions,
      });
    delete revisedActiveSearchFilters.champions;
    if (fuzzySearchTerm) {
      revisedActiveSearchFilters.keyword = decodeURIComponent(fuzzySearchTerm);
    }

    filtersQuery =
      new URLSearchParams(
        decamelizeKeys(revisedActiveSearchFilters),
      ).toString() || "";

    const searchQuery = query?.keyword
      ? `keyword=${query?.keyword}`
      : filtersQuery;

    return get(
      `universe/v1/search/search_by_filters?${searchQuery}&page=${page}&results_per_page=${pageSize}`,
    )
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint:
            "universe/v1/search/search_by_filters?:search_query&:page&:results_per_page",
        }),
      )
      .then(toJSON);
  },
  getSearchFilters: () =>
    get(`universe/v1/search_filters`)
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getSchools: (query) =>
    get(`universe/v1/search/schools_improved/?q=${query}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/search/schools_improved/?:q",
        }),
      )
      .then(toJSON),
});
