import useBadgesQuery from "../useBadgesQuery";
import useFilteredBadges from "../useFilteredBadges";
import { BADGES_TYPES } from "../../constants";

export default function useMonthlyBadgesQuery() {
  const {
    badges = [],
    isBadgesLoading: isMonthlyBadgesLoading,
    badgesError: monthlyBadgesError,
  } = useBadgesQuery();

  const { filteredBadges: monthlyBadges } = useFilteredBadges({
    badges,
    types: [BADGES_TYPES.MONTHLY],
  });

  return { monthlyBadges, isMonthlyBadgesLoading, monthlyBadgesError };
}
