import React from "react";
import PropTypes from "prop-types";
import { useRadioGroupState } from "react-stately";
import { useRadio, useRadioGroup } from "react-aria";
import { Image, classNames, Button, BUTTON_VARIANTS, BUTTON_SIZES } from "@gonoodle/gn-universe-ui";
import { useThrottle } from "rooks";

import { useLogEvent } from "../contexts/Analytics";
import { useChampions } from "../hooks";

const ChampionsRadioGroupContext = React.createContext(null);

function ChampionCard({ champion, isGraduated, analyticsProps }) {
  const ref = React.useRef(null);
  const { setSelectedValue, ...championsRadioGroupState } = React.useContext(
    ChampionsRadioGroupContext,
  );
  const { logEvent: logChampProfileViewed } = useLogEvent({
    event: "Champ Profile Viewed",
    properties: {
      champName: champion.name,
      ...analyticsProps,
    },
  });

  const { inputProps, isDisabled, isSelected } = useRadio(
    {
      id: champion.id,
      value: champion.id,
      isDisabled: isGraduated,
      "aria-label": `${champion.name}, ${champion.description}`,
    },
    {
      isDisabled: isGraduated,
      setSelectedValue: (value) => {
        setSelectedValue(value);
        logChampProfileViewed();
      },
      ...championsRadioGroupState,
    },
    ref,
  );

  return (
    <label className="group" htmlFor={champion.id}>
      <span className="sr-only">
        <input {...inputProps} ref={ref} />
      </span>
      <span
        className={classNames(
          "flex flex-col items-center text-center gap-2 p-2",
          isDisabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
      >
        <span
          className={classNames(
            "relative w-full aspect-[3/2] overflow-hidden rounded-lg",
            "transition-shadow motion-reduce:transition-none",
            isSelected
              ? "ring ring-white ring-offset-2 ring-offset-gray-900"
              : "ring-0",
          )}
        >
          <span
            className={classNames(
              "absolute inset-0 bg-purple group-focus-within:bg-white",
              "transition-colors motion-reduce:transition-none",
              isDisabled && "opacity-50",
            )}
          />
          <Image
            className={classNames(
              "absolute inset-0 w-full",
              isDisabled && "opacity-50",
            )}
            sources={{
              "regular@1x": isGraduated
                ? champion.images.phase5.headshot["1x"]
                : champion.images.phase1.headshot["1x"],
              "regular@2x": isGraduated
                ? champion.images.phase5.headshot["2x"]
                : champion.images.phase1.headshot["2x"],
              "regular@3x": isGraduated
                ? champion.images.phase5.headshot["3x"]
                : champion.images.phase1.headshot["3x"],
            }}
            alt=""
          />
          {isGraduated && (
            <span
              className="text-xs absolute right-2 top-2 uppercase py-1 px-1.5 rounded-md font-semibold bg-white"
              aria-hidden="true"
            >
              Graduated
            </span>
          )}
        </span>
        <span
          className={classNames("flex flex-col", isDisabled && "opacity-50")}
        >
          <span className="text-lg text-white font-semibold my-1">
            {champion.name}
          </span>
          <span className="text-xs text-purple-300">{champion.role}</span>
        </span>
      </span>
    </label>
  );
}

export default function ChampionSelect({
  defaultSelectedChampion,
  disabled,
  analyticsProps,
  unavailableChampionsIDs,
  onChange,
}) {
  const label = "champions Selection";
  const [isUsingRandom, setIsUsingRandom] = React.useState(false);
  const [selectedChampionCard, setSelectedChampionCard] = React.useState(
    defaultSelectedChampion,
  );
  const { champions } = useChampions();
  const [throttledOnChange] = useThrottle(onChange, 5000);
  const championsRadioGroupState = useRadioGroupState({
    label,
    value: selectedChampionCard?.id,
    isDisabled: disabled,
    onChange: (id) => {
      setIsUsingRandom(false);
      setSelectedChampionCard(
        champions.find((champion) => champion.id === Number(id)),
      );
    },
  });
  const { labelProps, radioGroupProps } = useRadioGroup(
    { "aria-label": label, isDisabled: disabled },
    championsRadioGroupState,
  );
  const { logEvent: logChampRandomizerUsed } = useLogEvent({
    event: "Champ Randomizer Used",
    properties: {
      ...analyticsProps,
    },
  });

  function selectRandomChampion() {
    const unselectedChampions = champions.filter(
      (champ) =>
        champ.id !== selectedChampionCard?.id &&
        !unavailableChampionsIDs.includes(champ.id),
    );

    if (unselectedChampions?.length) {
      const randomChampion =
        unselectedChampions[
          Math.floor(Math.random() * unselectedChampions.length)
        ];
      setIsUsingRandom(true);
      setSelectedChampionCard(randomChampion);
      logChampRandomizerUsed({ champName: randomChampion.name });
    }
  }

  return (
    <div className="flex items-start max-md:flex-col max-md:items-end w-full">
      <div className="flex flex-1 w-full md:px-md lg:px-lg">
        <div {...radioGroupProps} className="w-full">
          <span {...labelProps}>
            <span className="sr-only">{label}</span>
          </span>
          <ChampionsRadioGroupContext.Provider
            value={{
              ...championsRadioGroupState,
              analyticsProps,
            }}
          >
            <div className="grid gap-x-md gap-y-md grid-cols-2 lg:grid-cols-3">
              {champions.map((champion) => (
                <ChampionCard
                  key={champion.id}
                  champion={champion}
                  isGraduated={unavailableChampionsIDs.includes(champion.id)}
                  analyticsProps={analyticsProps}
                />
              ))}
            </div>
          </ChampionsRadioGroupContext.Provider>
        </div>
      </div>

      <div
        className={classNames(
          "sticky mt-2 flex flex-col space-y-2 md:top-lg md:mr-md lg:mr-lg",
          "max-md:bottom-sm max-md:w-full",
        )}
      >
        <div
          className={classNames(
            "flex flex-col items-center bg-purple p-6 rounded-md max-md:shadow-xl",
            !selectedChampionCard && "bg-purple-900",
          )}
        >
          <h2 className="sr-only">Selected Champ</h2>
          <p
            className={classNames(
              "gn-headline-sm text-white",
              !selectedChampionCard && "opacity-50",
            )}
          >
            {selectedChampionCard?.name ?? "Champ"}
          </p>
          {selectedChampionCard?.role && (
            <p className="text-sm mt-sm text-purple-300 text-center">
              {selectedChampionCard.role}
            </p>
          )}
          <div className="relative w-[200px] aspect-1 md:w-[300px] lg:w-[400px] xl:w-[500px]">
            {selectedChampionCard && (
              <Image
                className="absolute inset-0 w-full h-full object-contain p-8"
                sources={{
                  "regular@1x": selectedChampionCard.images.phase1.full["1x"],
                  "regular@2x": selectedChampionCard.images.phase1.full["2x"],
                  "regular@3x": selectedChampionCard.images.phase1.full["3x"],
                }}
                alt=""
              />
            )}
          </div>

          {selectedChampionCard?.background && (
            <p className="text-sm my-sm text-purple-300 w-[200px] md:w-[300px] lg:w-[400px] xl:w-[500px] text-center">
              {selectedChampionCard.background}
            </p>
          )}

          <Button
            className="w-full"
            testId="play-with-champion"
            variant={BUTTON_VARIANTS.light}
            size={BUTTON_SIZES.lg}
            disabled={!selectedChampionCard || disabled}
            onPress={() =>
              throttledOnChange(selectedChampionCard, isUsingRandom)
            }
          >
            {selectedChampionCard
              ? `Play with ${selectedChampionCard.name}`
              : `Choose your Champ`}
          </Button>
        </div>
        <Button
          className="w-full"
          variant={BUTTON_VARIANTS.dark}
          size={BUTTON_SIZES.lg}
          disabled={disabled}
          onPress={selectRandomChampion}
        >
          <span className="text-gray-400">Can&apos;t pick?</span>&nbsp;Give me a
          random Champ
        </Button>
      </div>
    </div>
  );
}

ChampionSelect.propTypes = {
  defaultSelectedChampion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    background: PropTypes.string,
    images: PropTypes.shape({
      phase1: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase2: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase3: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase4: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase5: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
  }),
  disabled: PropTypes.bool,
  unavailableChampionsIDs: PropTypes.arrayOf(PropTypes.number),
  analyticsProps: PropTypes.shape({}),
  onChange: PropTypes.func,
};

ChampionSelect.defaultProps = {
  defaultSelectedChampion: undefined,
  disabled: false,
  unavailableChampionsIDs: [],
  analyticsProps: {},
  onChange: () => {},
};

ChampionCard.propTypes = {
  champion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    background: PropTypes.string,
    images: PropTypes.shape({
      phase1: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase2: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase3: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase4: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase5: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
  }).isRequired,
  analyticsProps: PropTypes.shape({}),
  isGraduated: PropTypes.bool,
};

ChampionCard.defaultProps = {
  analyticsProps: {},
  isGraduated: false,
};
