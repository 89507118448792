import React from "react";
import PropTypes from "prop-types";

import Image from "../Image";
import { ImageSourcesType } from "../Image/types";

export default function PrintableCard({ title, sources, mode, format }) {
  return (
    <li className="relative group">
      <PrintableCard.Cover mode={mode} format={format}>
        <PrintableCard.Content sources={sources} />
      </PrintableCard.Cover>
      <PrintableCard.Info mode={mode} title={title} />
    </li>
  );
}

function Cover({ children, mode, format }) {
  return (
    <div
      data-testid="printable-card-cover"
      className={`block rounded overflow-hidden transform transition duration-500 ease-in-out bg-purple md:bg-gray-800 group-hover:bg-purple aspect-h-9  
      ${mode === "dark" ? "shadow-darkSM" : "shadow-lightSM"}
      ${format === "landscape" && "aspect-w-16"}
      ${format === "portrait" && "aspect-w-7"}
      ${format === "square" && "aspect-w-9"}
       `}
    >
      {children}
    </div>
  );
}

function Content({ sources }) {
  return (
    <Image
      className="object-cover pointer-events-none transform transition duration-200 group-hover:scale-110"
      sources={sources}
      alt=""
    />
  );
}

function Info({ title, description, mode }) {
  return (
    <>
      <h3
        className={`gn-text-sm mt-xs transition duration-200 ease-in-out line-clamp-3 ${
          mode === "dark" ? "text-white" : "text-black"
        }`}
      >
        {title}
      </h3>
      {description && (
        <p className="gn-text-xs mt-xs text-gray-500 line-clamp-4">
          {description}
        </p>
      )}
    </>
  );
}

PrintableCard.Cover = Cover;
PrintableCard.Content = Content;
PrintableCard.Info = Info;

Cover.propTypes = {
  format: PropTypes.oneOf(["landscape", "portrait", "square"]),
  children: PropTypes.node.isRequired,
  mode: PropTypes.oneOf(["dark", "light"]),
};

Cover.defaultProps = {
  format: "landscape",
  mode: "light",
};

Content.propTypes = {
  sources: ImageSourcesType.isRequired,
};

Info.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Info.defaultProps = {
  mode: "light",
  description: null,
};

PrintableCard.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  format: PropTypes.oneOf(["landscape", "portrait", "square"]),
  sources: ImageSourcesType.isRequired,
  title: PropTypes.string.isRequired,
};

PrintableCard.defaultProps = {
  format: "landscape",
  mode: "light",
};
