import React from "react";
import { useRouter } from "next/router";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUser } from "../contexts/user";
import { getReferrerFromRouter } from "../contexts/Analytics";
import { getAnalyticsParams } from "../utils/analytics";
import api from "../api";
import BugsnagClient from "../utils/bugsnag";
import { QUERY_KEYS } from "../constants";
import usePlayer from "../components/Player/usePlayer";
import usePlaybackTimeInterval from "../components/Player/usePlaybackTimeInterval";
import usePlayback from "../components/Player/usePlayback";
import useOnce from "./useOnce";

const client = api();

/**
 * Hook for creating an activity session for a given video ID.
 * @param {string} videoId - The ID of the video for which to create the activity session.
 * @returns {object} An object that contains the following properties:
 * * createSession: A function that creates the activity session.
 * * session: The created activity session data.
 */
function useCreateSession(videoId) {
  const router = useRouter();
  const analyticsParams = getAnalyticsParams(router.query);
  const [isAutoPlayed, setIsAutoPlayed] = React.useState(false);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setIsAutoPlayed(!!urlParams.get("autoPlayed"));
  }, []);

  const { mutate: createSession, data: session } = useMutation(
    () =>
      client.createActivitySession({
        videoVersionId: videoId,
        autoplayed: isAutoPlayed,
        ...(getReferrerFromRouter(router)
          ? getReferrerFromRouter(router)
          : analyticsParams),
      }),
    {
      retry: 6,
      onSettled: () => {
        try {
          // TODO: Added to debug missing analytics data remove once we have more info.
          const analyticsData = getReferrerFromRouter(router)
            ? getReferrerFromRouter(router)
            : analyticsParams;
          if (!analyticsData.sourcePage || !analyticsData.sourcePageType) {
            BugsnagClient.notify({
              name: "Missing analytics properties",
              message: `Activity session for video ${videoId} has missing properties, passed properties: ${
                analyticsData ? JSON.stringify(analyticsData) : undefined
              }`,
            });
          }
        } catch (e) {
          BugsnagClient.notify(e);
        }
      },
    },
  );

  return {
    createSession,
    session,
  };
}

/**
 * Hook for persisting session data to the server.
 * @param {function} onShowAd - Callback function to execute when an ad should be shown.
 * @param {function} onPointsAwarded - Callback function to execute when points are awarded.
 * @returns {function} - Function to persist session data to the server.
 */
function usePersistSession(onShowAd, onPointsAwarded) {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const onPointsAwardedOnce = useOnce((...args) => {
    queryClient.invalidateQueries([QUERY_KEYS.USER]);
    onPointsAwarded(...args);
  });

  const { mutate: updateSession } = useMutation(
    ({ sessionId, seconds }) =>
      client.updateActivitySession(sessionId, { seconds }),
    {
      mutationKey: "updateActivitySession",
      onSuccess: ({ showAd, pointsAwarded }) => {
        if (user.showAd !== showAd) {
          queryClient.invalidateQueries([QUERY_KEYS.USER]);
          onShowAd(showAd);
        }
        if (pointsAwarded) {
          onPointsAwardedOnce(pointsAwarded);
        }
      },
    },
  );

  const persistSession = React.useCallback(
    (session, seconds) => {
      if (session) {
        updateSession({
          sessionId: session.activitySessionId,
          seconds,
        });
      }
    },
    [updateSession],
  );

  return persistSession;
}

export default function useVideoSession({
  videoId,
  onShowAd = () => {},
  onPointsAwarded = () => {},
}) {
  const player = usePlayer();
  const [playbackState] = usePlayback();
  const { createSession, session } = useCreateSession(videoId);
  const createSessionOnce = useOnce(createSession);
  const persistSession = usePersistSession(onShowAd, onPointsAwarded);
  // This hook is used to flush the session data to the server every 10 seconds.
  const { flush } = usePlaybackTimeInterval(
    (seconds) => persistSession(session, seconds),
    10,
  );

  React.useEffect(() => {
    let unsubscribe = () => {};

    unsubscribe = player.onPlay(createSessionOnce);

    return () => unsubscribe();
  }, [createSession, createSessionOnce, player]);

  // Flush the session data to the server if the video is completed or paused.
  React.useEffect(() => {
    if (playbackState.completed || playbackState.paused) {
      flush();
    }
  }, [flush, playbackState.completed, playbackState.paused]);

  // Flush the session data to the server if navigating away.
  React.useEffect(
    () => () => {
      flush();
    },
    [flush],
  );

  return session;
}
