export default ({ get, checkStatus, toJSON }) => ({
  spotlight: (id) =>
    get(`universe/v1/home_page_spotlight/search?spotlight_ids=[${id}]`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/home_page_spotlight/search?:spotlight_ids",
        }),
      )
      .then(toJSON),
});
