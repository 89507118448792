export default {
  HOME: "/",
  VIDEOS: "/videos/:id/:slug",
  ACTIVITIES: "/activities/:id/:slug",
  TAGS: "/tags/:id/:slug",
  GAMES: "/games/:id/:slug",
  POST: "/company/posts/:slug",
  forgotPassword: "/forgot-password",
  REGISTRATION: "/registration",
  LOGIN: "/login",
  PROFILES: "/profiles",
  SEARCH: "/search",
};
