import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useRecommendedVideosQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: recommendedVideos,
    isLoading: isRecommendedVideosLoading,
    isError: recommendedVideosError,
  } = useQuery(
    [QUERY_KEYS.RECOMMENDED_VIDEOS, slug],
    () => client.getRecommendedVideos(slug),
    {
      staleTime: oneHour * 4,
      ...options,
    },
  );

  return {
    recommendedVideos,
    isRecommendedVideosLoading,
    recommendedVideosError,
  };
}
