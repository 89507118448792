import React from "react";
import PropTypes from "prop-types";

import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from "../Button";
import { Link } from "../../../utils/Link";
import { useAssetsConfig } from "../../contexts/AssetsConfig";

export default function GameHero({
  title,
  description,
  buttonLabel,
  onClick,
  imgSources,
  reverse,
  border,
  appLinks,
}) {
  const { assetsUrl } = useAssetsConfig();
  return (
    <div className={`${reverse ? "w-full mb-xl" : "w-full mb-xl"}`}>
      <div className="relative w-full flex flex-col lg:flex-row bg-gray-800 rounded-lg">
        <div
          className={`${
            reverse
              ? "order-first lg:order-last transform -translate-y-lg lg:translate-x-xl"
              : "order-first transform -translate-y-lg lg:-translate-x-xl"
          } h-full w-full lg:w-3/5 `}
        >
          <div className={`${border && "p-md rounded-lg"}`}>
            <img
              className="object-cover w-full h-full"
              src={imgSources}
              alt={title}
            />
          </div>
        </div>
        <div
          className={`${
            reverse ? "order-last lg:order-first" : "order-last"
          } flex flex-col justify-center overflow-hidden items-start w-full lg:w-2/4 space-y-md container py-md`}
        >
          <p className="gn-headline-lg text-white">{title}</p>
          <p className="gn-text text-white">{description}</p>
          {appLinks ? (
            <div className="flex flex-wrap">
              <Link
                href="https://apps.apple.com/app/gonoodle-games/id1497169748"
                className="block mr-sm mb-sm"
              >
                <span className="sr-only">
                  Download GoNoodle Video App on the Apple App Store
                </span>
                <img
                  src={`${assetsUrl}/web_public/badge-apple-appStore.svg`}
                  alt="Apple App Store Badge"
                  className="h-lg"
                />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.gonoodle.gonoodlegames"
                className="block mr-sm mb-sm"
              >
                <span className="sr-only">Get it on Google Play</span>
                <img
                  src={`${assetsUrl}/web_public/badge-playStore.svg`}
                  alt="Google Play Badge"
                  className="h-lg"
                />
              </Link>
            </div>
          ) : (
            <Button
              variant={BUTTON_VARIANTS.light}
              size={BUTTON_SIZES.md}
              onPress={onClick}
              className="font-bold"
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

GameHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  imgSources: PropTypes.shape({
    "regular@1x": PropTypes.string,
    "regular@2x": PropTypes.string,
    "regular@3x": PropTypes.string,
  }).isRequired,
  reverse: PropTypes.bool,
  border: PropTypes.bool,
  appLinks: PropTypes.bool,
};

GameHero.defaultProps = {
  reverse: false,
  border: false,
  appLinks: false,
  buttonLabel: "",
};
