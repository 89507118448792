import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";

const {
  Root,
  Viewport,
  List,
  Item,
  Content,
  Trigger,
  Link,
  Sub,
} = NavigationMenuPrimitive;

export { Root, Viewport, List, Item, Content, Trigger, Link, Sub };
