import React from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export const selectData = [
  { id: 1, name: "Most Recent" },
  { id: 2, name: "Ascending (A-Z)" },
  { id: 3, name: "Descending (Z-A)" },
];

export const selectProps = {
  id: "test",
  labelId: "test-label",
  options: selectData,
  label: "label",
  helpText: (
    <p>
      Learn more{" "}
      <a className="text-purple" href="https://www.gonoodle.com">
        here
      </a>
    </p>
  ),
  required: undefined,
  disabled: undefined,
  error: undefined,
  mode: "light",
  inputProps: {
    endAdornment: <ChevronDownIcon className="h-input-icon text-gray-500" />,
  },
  leadingText: "Sort:",
  buttonClassNames: "border h-button-sm border-gray-500 gn-text-xs px-sm pr-lg",
  optionClassNames: "pl-lg",
  optionTextClassNames: "gn-text-xs font-semibold",
  checkmark: false,
  onChange: () => {},
  value: selectData[0],
  focusRingClassNames: "focus:purple",
};
