import React from "react";
import PropTypes from "prop-types";

import { useAssetsConfig } from "../../contexts/AssetsConfig";

export default function TalkyChamp({ text }) {
  const { assetsUrl } = useAssetsConfig();
  return (
    <div>
      <div className="max-w-56 bg-purple-100 rounded-lg relative left-28 top-14">
        <p className="gn-text-sm gn-word-break p-md">{text}</p>
        <svg
          className="absolute"
          style={{ bottom: "-22px", left: "15%" }}
          width="43"
          height="22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.21022 0C8.65927 6.75317 6.69455 17.1031 0 21.3501c0 0 27.4591 6.4595 43-21.3501H9.21022Z"
            fill="#F0E8FF"
          />
        </svg>
      </div>
      <div className="w-36 h-48 relative">
        <img
          className=" object-fill"
          src={`${assetsUrl}/web_public/McPufferson-L1.svg`}
          alt=""
        />
      </div>
    </div>
  );
}

TalkyChamp.propTypes = {
  text: PropTypes.string.isRequired,
};
