import { useEffect, useState, useRef } from "react";
import { useInView as useInViewLib } from "react-intersection-observer";

import inBrowser from "../helpers/inBrowser";

const loadIntersectionObserver = () => import("intersection-observer");
const hasNativeIntersectionObserver =
  inBrowser() && typeof window.IntersectionObserver !== "undefined";
let isIntersectionObserverAvailable = hasNativeIntersectionObserver;

function useInView(options) {
  const noRef = useRef(null);
  const mounted = useRef(false);
  const result = useInViewLib(options);
  const [
    isIntersectionObserverReady,
    setIsIntersectionObserverReady,
  ] = useState(isIntersectionObserverAvailable);

  useEffect(() => {
    mounted.current = true;

    if (!isIntersectionObserverAvailable) {
      loadIntersectionObserver().then(() => {
        isIntersectionObserverAvailable = true;
        if (mounted.current) {
          setIsIntersectionObserverReady(true);
        }
      });
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  return isIntersectionObserverReady
    ? result
    : {
        ...result,
        ref: noRef,
      };
}

export default hasNativeIntersectionObserver ? useInViewLib : useInView;
