import React from "react";

export default function useSlot() {
  // Assume we do have the slot in the initial render.
  const [hasSlot, setHasSlot] = React.useState(true);
  const hasRun = React.useRef(false);

  // A callback ref which will run when the slotted element mounts.
  // This should happen before the useLayoutEffect below.
  const ref = React.useCallback((el) => {
    hasRun.current = true;
    setHasSlot(!!el);
  }, []);

  // If the callback hasn't been called, then reset to false.
  React.useLayoutEffect(() => {
    if (!hasRun.current) {
      setHasSlot(false);
    }
  }, []);

  return [ref, hasSlot];
}
