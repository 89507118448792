export default ({ post, checkStatus }) => ({
  postImpressionEvents: ({ event, properties }) =>
    post("universe/v1/events", {
      body: { event, properties },
    }).then((response) => checkStatus({ response, method: "POST" })),
  trackAnalyticsEvents: ({ event, properties }) =>
    post("universe/v1/analytics", {
      body: { event, properties },
    }).then((response) => checkStatus({ response, method: "POST" })),
});
