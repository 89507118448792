export default {
  CLEVER_SIGNUP: "signup",
  GOOGLE_SIGNUP: "google-signup",
  CLEVER_LOGIN: "login",
  GOOGLE_LOGIN: "google-login",
  CLEVER_CONNECT: "connect",
  GOOGLE_CONNECT: "google-connect",
  CLEVER: "clever",
  GOOGLE: "google",
};
