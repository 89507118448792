export default ({ get, checkStatus, toJSON }) => ({
  getAdProducts: () =>
    get(`universe/v1/ad_product_partners`)
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getPreRollSlate: (id, tag) => {
    const params = tag
      ? `?video_version_id=${id}&content_tag_id=${tag}`
      : `?video_version_id=${id}`;
    return get(`universe/v1/pre_roll_slate${params}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/pre_roll_slate?:params",
        }),
      )
      .then(toJSON);
  },
});
