import React from "react";

export default function PressFooter() {
  return (
    <div className="bg-gray-800">
      <div className="flex flex-row flex-wrap md:flex-nowrap md:justify-evenly md:content-center h-96 md:h-52">
        <div className="self-center mx-auto md:mx-0">
          <p className="gn-headline-sm text-white">Media Inquiries</p>
          <p className="gn-text text-white pt-md">press@gonoodle.com</p>
        </div>
      </div>
    </div>
  );
}
