import React from "react";
import PropTypes from "prop-types";

export default function AlertBox({ text, color, className }) {
  const colors = {
    mint: "bg-mint",
    red: "bg-red",
  };

  return (
    <div
      className={`w-full flex rounded-md ${colors[color]} ${className}`}
      data-testid="alert-box-wrapper"
    >
      <p className="w-full gn-word-break gn-text-sm text-white p-sm">{text}</p>
    </div>
  );
}

AlertBox.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["red", "mint"]),
  className: PropTypes.string,
};

AlertBox.defaultProps = {
  color: "red",
  className: undefined,
};
