import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useProfileChampionsQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: profileChampions = [],
    isLoading: isProfileChampionsLoading,
    isError: profileChampionsError,
  } = useQuery([QUERY_KEYS.PROFILE_CHAMPIONS], client.getProfileChampions, {
    staleTime: oneHour * 24,
    ...options,
  });

  return { profileChampions, profileChampionsError, isProfileChampionsLoading };
}
