import React from "react";
import PropTypes from "prop-types";
import PrimitiveMuxPlayer from "@mux/mux-player-react";
import isFunction from "lodash/isFunction";
import { twMerge } from "tailwind-merge";

import { VIDEO_STATES } from "../../../constants";
import styles from "./mux.module.css";

const MuxPlayer = React.forwardRef(function Player(
  {
    playbackId,
    autoPlay,
    children,
    onPlay = () => {},
    onPause = () => {},
    onComplete = () => {},
    onPlayAttempt = () => {},
    onFullscreenChange = () => {},
    onPlayerReady = () => {},
    onError = () => {},
  },
  ref,
) {
  const [playerState, setPlayerState] = React.useState(VIDEO_STATES.LOADING);
  const [isPlayerReady, setIsPlayerReady] = React.useState(false);
  const innerPlayerRef = React.useRef(null);
  const onPlayAttemptRef = React.useRef(onPlayAttempt);
  onPlayAttemptRef.current = onPlayAttempt;

  const primitiveOnPlay = React.useCallback(({ callback }) => {
    const player = innerPlayerRef.current;
    const attempt = onPlayAttemptRef.current();

    if (attempt === false) {
      player.pause();
      setPlayerState(VIDEO_STATES.PAUSED);
    } else {
      setPlayerState(VIDEO_STATES.PLAYING);
      callback();
    }
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      pause: () => isPlayerReady && innerPlayerRef.current.pause(),
      play: () => isPlayerReady && innerPlayerRef.current.play(),
      getState: () => playerState,
      onPlay: (callback) => {
        const player = innerPlayerRef.current;
        if (!isFunction(callback)) return undefined;

        const onPlayHandler = () => {
          primitiveOnPlay({ callback });
        };

        if (isFunction(player.addEventListener))
          player.addEventListener("play", onPlayHandler);

        return () =>
          isFunction(player.removeEventListener) &&
          player.removeEventListener("play", onPlayHandler);
      },
      onPause: (callback) => {
        const player = innerPlayerRef.current;
        if (!isFunction(callback)) return undefined;

        const handlePause = () => {
          callback();
        };

        if (isFunction(player.addEventListener))
          player.addEventListener("pause", handlePause);

        return () =>
          isFunction(player.removeEventListener) &&
          player.removeEventListener("pause", handlePause);
      },
      onComplete: (callback) => {
        const player = innerPlayerRef.current;
        if (!isFunction(callback)) return undefined;

        const handleComplete = () => {
          callback();
        };

        if (isFunction(player.addEventListener))
          player.addEventListener("ended", handleComplete);

        return () =>
          isFunction(player.removeEventListener) &&
          player.removeEventListener("ended", handleComplete);
      },
      onTimeUpdate: (callback) => {
        const player = innerPlayerRef.current;
        if (!isFunction(callback)) return undefined;

        const handleTimeUpdate = () => {
          callback({
            position: innerPlayerRef.current.currentTime,
          });
        };

        if (isFunction(player.addEventListener))
          player.addEventListener("timeupdate", handleTimeUpdate);

        return () =>
          isFunction(player.removeEventListener) &&
          player.removeEventListener("timeupdate", handleTimeUpdate);
      },
    }),
    [isPlayerReady, playerState, primitiveOnPlay],
  );

  React.useEffect(() => {
    let prevInFullscreen = false;

    const handleFullscreenChange = () => {
      const isInFullscreen =
        document.fullscreenElement === innerPlayerRef.current;
      if (isInFullscreen) {
        prevInFullscreen = true;
        onFullscreenChange(true);
      }

      if (!isInFullscreen && prevInFullscreen) {
        prevInFullscreen = false;
        onFullscreenChange(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange,
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange,
      );
    };
  }, [onFullscreenChange]);

  const primitiveOnPause = React.useCallback(() => {
    onPause();
    setPlayerState("paused");
  }, [onPause]);

  const primitiveOnEnded = React.useCallback(() => {
    onComplete();
    setPlayerState("completed");
  }, [onComplete]);

  React.useEffect(() => {
    if (isPlayerReady === false) {
      setIsPlayerReady(true);
      onPlayerReady();
    }
  }, [isPlayerReady, onPlayerReady]);

  React.useEffect(() => {
    let timeoutId;

    if (autoPlay === true) {
      // This is a workaround to make sure the player plays when autoPlay is true
      try {
        // Wait for the player to figure out configuration that will allow autoplay
        timeoutId = setTimeout(() => {
          innerPlayerRef.current?.play();
        }, 1000);
      } catch (e) {
        /* empty */
      }
    }

    return () => clearTimeout(timeoutId);
  }, [autoPlay]);

  return (
    <div className={twMerge("relative", styles.muxPlayerWrapper)}>
      <PrimitiveMuxPlayer
        ref={innerPlayerRef}
        playbackId={playbackId}
        autoPlay={autoPlay === true ? "any" : false}
        envKey={process.env.NEXT_PUBLIC_MUX_ENV_KEY}
        accentColor="#7328ff" // Gonoodle purple represents purple in tailwind
        onPlay={() => primitiveOnPlay({ callback: onPlay })}
        onPause={primitiveOnPause}
        onEnded={primitiveOnEnded}
        onError={onError}
      />

      <div className="absolute inset-0 z-50 overflow-hidden pointer-events-none">
        {children}
      </div>
    </div>
  );
});

MuxPlayer.propTypes = {
  playbackId: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  children: PropTypes.node,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onComplete: PropTypes.func,
  onPlayAttempt: PropTypes.func,
  onFullscreenChange: PropTypes.func,
  onPlayerReady: PropTypes.func,
  onError: PropTypes.func,
};

export default MuxPlayer;
