import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useContentTagQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: contentTag,
    isLoading: isContentTagLoading,
    error: contentTagError,
  } = useQuery(
    [QUERY_KEYS.CONTENT_TAG, slug],
    () => client.getContentTag(slug),
    {
      staleTime: oneHour * 4,
      ...options,
    },
  );

  return { contentTag, isContentTagLoading, contentTagError };
}
