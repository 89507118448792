/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";

import { ImageSourcesType } from "../Image/types";
import Image from "../Image";

export default function Header({
  mode,
  bgSources,
  tagLogoSources,
  sponsorLogo,
  sponsorName,
  sponsorUrl,
  title,
  description,
  size,
  bgColor,
}) {
  const isDark = mode === "light";
  const hasBgImage = bgSources && Object.keys(bgSources).length;
  const hasLogo = tagLogoSources && Object.keys(tagLogoSources).length;
  const hasSponsor = sponsorLogo;
  const hasSponsorUrl = sponsorUrl;
  const isSmall = size === "sm";
  const isLarge = size === "lg";
  const sizeClasses = isLarge
    ? "min-h-header-sm md:min-h-header-md lg:min-h-header-lg 2xl:min-h-header-xl"
    : size === "md"
    ? "min-h-header-xs"
    : "min-h-header-xs py-xl";
  const bgColorClasses = {
    mint: "bg-mint",
    yellow: "bg-yellow",
  };

  return (
    <div
      className={`relative flex justify-center items-center py-lg ${sizeClasses} ${
        isDark ? "text-black" : "text-white"
      }`}
    >
      {/* Background */}
      <div
        className={`absolute inset-0 overflow-hidden ${
          hasBgImage ? "bg-gray-900" : `${bgColorClasses[bgColor]}`
        }`}
      >
        {hasBgImage ? (
          <>
            <Image
              className={`
                absolute left-1/2 transform -translate-x-1/2 object-cover max-w-none w-screen-sm sm:w-screen-lg md:w-screen-xl lg:w-screen-2xl 2xl:w-screen
                ${isLarge ? "bottom-0" : "top-1/2 -translate-y-1/2"}
              `}
              sources={bgSources}
              alt={`Header for ${title}`}
            />
            <div
              className="absolute inset-0 mix-blend-multiply"
              aria-hidden="true"
            />
          </>
        ) : null}
      </div>
      {/* Content */}
      <div
        className={`relative flex flex-col justify-center items-center text-center ${
          isSmall ? "max-w-3/4 md:max-w-screen-lg" : "w-[35%]"
        } ${isLarge ? "-mt-xl sm:-mt-2xl" : ""}`}
      >
        {hasLogo ? (
          <Image
            className="max-w-2/3 sm:max-w-1/3 md:max-w-2/3"
            sources={tagLogoSources}
            alt={`Logo for ${title}`}
          />
        ) : (
          <h1
            className={`${isSmall ? "gn-page-header-sm" : "gn-page-header-lg"}`}
          >
            <span className="sr-only">{`This page is about ${title}`}</span>
            {title}
          </h1>
        )}
        {description ? (
          <h2
            className={`gn-subtitle mt-sm md:mt-md ${
              isSmall ? "md:px-2xl" : "px-lg md:px-md"
            }`}
          >
            {description}
          </h2>
        ) : null}
        {hasSponsor && !isSmall ? (
          hasSponsorUrl ? (
            <a
              href={sponsorUrl}
              className="cursor-pointer"
              data-testid="headerSponsorLogoLink"
            >
              <p className="gn-caption mb-xs mt-sm md:mt-md">Powered by</p>
              <img
                className="max-w-2xl md:max-w-3xl md:px-md"
                src={sponsorLogo}
                alt={`${title} is powered by ${sponsorName}`}
              />
            </a>
          ) : (
            <>
              <p className="gn-caption mb-xs mt-sm md:mt-md">Powered by</p>
              <img
                className="max-w-2xl md:max-w-3xl md:px-md"
                src={sponsorLogo}
                alt={`${title} is powered by ${sponsorName}`}
              />
            </>
          )
        ) : null}
      </div>
    </div>
  );
}

Header.propTypes = {
  /** Sets the text color for legibility on various background colors */
  mode: PropTypes.oneOf(["dark", "light"]),
  /** Source set for an Image component. Renders as the background of the header. */
  bgSources: ImageSourcesType,
  /** Source set for an Image component. Renders above the description */
  tagLogoSources: ImageSourcesType,
  /** Img src for the sponsor's logo. Renders under the desription */
  sponsorLogo: PropTypes.string,
  /** The name of the sponsor. Used in alt text for the sponsor logo */
  sponsorName: PropTypes.string,
  /** Link to the sponsor's website. Clicking the sponsor's logo opens this link */
  sponsorUrl: PropTypes.string,
  /** A title for the header. Visible for small headers or headers without tagLogos and used in alt text for the tagLogo */
  title: PropTypes.string.isRequired,
  /** A description for the header */
  description: PropTypes.string,
  /** Sets the height of the header */
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  /** Sets the background color of the header */
  bgColor: PropTypes.oneOf(["yellow", "mint"]),
};

Header.defaultProps = {
  mode: "light",
  bgSources: {},
  tagLogoSources: {},
  sponsorLogo: null,
  sponsorName: null,
  sponsorUrl: "",
  description: null,
  size: "sm",
  bgColor: "yellow",
};
