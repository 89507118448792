import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";
import { useUser } from "../contexts/user";

const client = api();

export default function useCurriculumQuery(slug, options = {}) {
  const { user } = useUser();
  const oneHour = 1000 * 60 * 60;

  // Determine which API method to use based on the feature flag
  const fetchCurriculum = user.features.useDeprecatedCurriculum
    ? () => client.getDeprecatedCurriculum(slug)
    : () => client.getCurriculum(slug);

  const {
    data: curriculum,
    isLoading: isCurriculumLoading,
    isError: curriculumError,
  } = useQuery([QUERY_KEYS.CURRICULUM, slug], fetchCurriculum, {
    staleTime: oneHour * 4,
    ...options,
  });

  return { curriculum, isCurriculumLoading, curriculumError };
}
