import React from "react";
import { StarIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "@gonoodle/gn-universe-utils";
import { Pressable } from "@gonoodle/gn-universe-ui";
import LottiePlayer from "react-lottie-player/dist/LottiePlayerLight";

import { VIDEO_STATES } from "../../constants";
import trophyEarnedAnimation from "../../public/lottie/notifications/trophy-earned.json";

export default function PointsProgressBar({
  value = 0,
  min = 0,
  max = 10,
  showTrophy = true,
  playbackState,
  animateOnMount = false,
  onTrophyPress = () => {},
}) {
  const [currentPillNode, setCurrentPillNode] = React.useState();
  const [enableAnimation, setEnableAnimation] = React.useState(animateOnMount);
  const lottiePlayerRef = React.useRef();

  // This hook is used to ensure that progressFillWidth gets recalculated when window size changes.
  // We avoid storing progressFillWidth directly in the state that updates on window resize because
  // it would cause a flicker on the initial render of the component.
  useWindowSize();

  const currentPill = Math.max(min, Math.min(value, max));
  const prevPillRef = React.useRef(currentPill);
  const progressFillWidth =
    currentPillNode &&
    typeof currentPillNode.offsetLeft === "number" &&
    typeof currentPillNode.offsetWidth === "number"
      ? // Add 10 to the width to make the progress fill extend past the current pill as offset.
        currentPillNode.offsetLeft + currentPillNode.offsetWidth + 10
      : 0;

  // Prevent animation when there is no change in the current pill.
  React.useEffect(() => {
    if (animateOnMount !== true) {
      if (currentPill !== prevPillRef.current) {
        setEnableAnimation(true);
      } else {
        setEnableAnimation(false);
      }
    }

    prevPillRef.current = currentPill;
  }, [currentPill, animateOnMount]);

  React.useEffect(() => {
    // This useEffect hook adjusts the fill color of the SVG path within the Lottie animation
    // based on the currentPill value reaching maxValue. The SVG path is targeted directly
    // via DOM manipulation because Lottie does not provide a built-in method to programmatically
    // change properties post-render. This approach requires knowing the specific structure
    // and styles of the 'trophyEarnedAnimation'.
    //
    // Important: The fill color values "#9258ff" (purple-400) for the maxValue and "#3d1687" for other values
    // are hardcoded based on the current design of the 'trophyEarnedAnimation'. If the animation
    // file changes or the design of the animation is updated, these fill values must be reviewed
    // and potentially updated to match the new animation design. Always verify that the SVG path
    // still exists and that the fill colors align with the new design aesthetics.

    if (lottiePlayerRef.current) {
      const svgPath = lottiePlayerRef.current.wrapper.querySelector("svg path");
      if (svgPath) {
        svgPath.style.transition = "fill 1s 1s";

        svgPath.style.fill = currentPill === max ? "#9258ff" : "#3d1687";
      }
    }
  }, [currentPill, max]);

  return (
    <div className="flex flex-col isolate">
      {showTrophy && (
        <Pressable
          onPress={onTrophyPress}
          className="self-end h-12 w-fit outline-none rounded-full translate-y-[1px] lg:-translate-x-4"
        >
          <LottiePlayer
            ref={lottiePlayerRef}
            animationData={trophyEarnedAnimation}
            loop={playbackState !== VIDEO_STATES.PLAYING}
            play={playbackState !== VIDEO_STATES.PLAYING}
            className="h-full w-full"
          />
        </Pressable>
      )}

      <div className="relative overflow-hidden flex flex-row items-center lg:rounded-full bg-[#3d1687] py-2">
        <div
          className={twMerge(
            "absolute left-0 top-0 h-full lg:rounded-full bg-gradient-to-b from-purple-400 to-purple",
            currentPill === max &&
              "shadow-[0px_0px_22px_3px_var(--tw-shadow-color)]",
            enableAnimation && "transition-all delay-500 duration-1000",
          )}
          style={{
            width: currentPill === max ? "100%" : `${progressFillWidth}px`,
          }}
        />

        <div className="z-10 flex flex-row justify-evenly items-center w-full">
          {Array.from({ length: max }, (_, index) => {
            const point = index + 1;

            return (
              <span
                key={point}
                ref={(node) => {
                  if (point === currentPill) {
                    setCurrentPillNode(node);
                  }
                }}
                className={twMerge(
                  "w-5 h-5 lg:w-10 lg:h-10 rounded-full flex-shrink-0 text-purple flex justify-center items-center font-bold text-sm lg:text-lg",
                  point <= currentPill ? "bg-white" : "bg-[#5D15E3]",
                  point !== currentPill && "scale-75 lg:scale-50", // Using scale to make the pill smaller
                  enableAnimation && "transition-all duration-500",
                  point !== max && "lg:mr-auto",
                  point === 1 && "lg:ml-auto",
                )}
              >
                {point === currentPill && currentPill}
              </span>
            );
          })}
        </div>

        <span className="z-10 hidden lg:flex flex-row items-center p-2 space-x-1 mx-4 rounded-full border-[3px] border-solid">
          <StarIcon className="w-[18px] h-[18px] text-gold shrink-0" />
          <span className="text-white font-bold text-md leading-4">
            {currentPill}/{max}
          </span>
        </span>
      </div>
    </div>
  );
}
