export default ({ get, put, post, checkStatus, toJSON }) => ({
  getChampions: () =>
    get(`universe/v1/champs`)
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getProfileChampions: () =>
    get(`universe/v1/classroom_champs`)
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  levelUpChamp: () =>
    put(`universe/v1/classroom_champs/level_up`)
      .then((response) => checkStatus({ response, method: "PUT" }))
      .then(toJSON),
  chooseNewChamp: (id) =>
    post(`universe/v1/classroom_champs`, {
      body: {
        classroomChamp: {
          champId: id,
        },
      },
    }).then((response) => checkStatus({ response, method: "POST" })),
  resetChampionsProgress: (id) =>
    post(`universe/v1/classroom_champs/reset_all`, {
      body: {
        classroomChamp: {
          champId: id,
        },
      },
    }).then((response) => checkStatus({ response, method: "POST" })),
});
