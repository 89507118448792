import { useRef } from "react";
import { random } from "lodash";

import useAdProductsOfTypeQuery from "./useAdProductsOfTypeQuery";
import { selectAdProduct } from "../selectors";

export default function useRotatedAdProductOfTypeQuery(type) {
  const randNumRef = useRef(random(1, 100));

  const {
    adProducts: adProductsOfType,
    isAdProductsLoading,
    adProductsError,
  } = useAdProductsOfTypeQuery(type);

  return {
    adProduct: selectAdProduct(adProductsOfType, randNumRef.current),
    isAdProductsLoading,
    adProductsError,
  };
}
