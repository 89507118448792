import React from "react";
import PropTypes from "prop-types";

import { useAssetsConfig } from "../../contexts/AssetsConfig";

import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from "../Button";

export default function ExternalLinkDisclaimer({ time, onClick }) {
  const { assetsUrl } = useAssetsConfig();
  return (
    <div className="h-screen w-screen flex flex-col justify-center bg-white relative">
      <div className="flex flex-col items-center container text-center md:text-left">
        <div className="flex flex-col md:flex-row items-center space-y-md md:space-y-0 w-full md:max-w-screen-lg">
          <div className="w-full">
            <h2 className="gn-page-header-sm mb-md">
              You are about to leave GoNoodle.com
            </h2>
            <h2 className="gn-text md:gn-headline-sm mb-xl">
              This screen will disappear in {time}{" "}
              {time === 1 ? "second" : "seconds"}...
            </h2>
            <div className="my-sm">
              <Button variant={BUTTON_VARIANTS.dark} size={BUTTON_SIZES.md} onPress={onClick}>
                Take me back to GoNoodle!
              </Button>
            </div>
          </div>
        </div>
        <div>&nbsp;</div>
      </div>
      <img
        className="w-3xl absolute bottom-0 right-0"
        src={`${assetsUrl}/web_public/shane-fonda-2.png`}
        alt="Shane Fonda running away"
      />
    </div>
  );
}

ExternalLinkDisclaimer.propTypes = {
  time: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
