import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";
import { useInterval } from "@gonoodle/gn-universe-utils";

const missingOneTrustProvider =
  "You forgot to wrap your app in <OneTrustProvider>";
const OneTrustContext = React.createContext({
  get isReady() {
    throw new Error(missingOneTrustProvider);
  },
});
OneTrustContext.displayName = "OneTrustContext";

export const PERFORMANCE_SDKS = "C0002";
export const CONSENT_NOT_COLLECTED = -1;
export const CONSENT_NOT_GIVEN = 0;
export const CONSENT_GIVEN = 1;

const ONETRUST_BANNER_ID = "onetrust-banner-sdk";

const getConsentStatus = (categoryId) => {
  if (window.OnetrustActiveGroups.includes(categoryId)) {
    return CONSENT_GIVEN;
  }
  return CONSENT_NOT_GIVEN;
};

const isOneTrustBannerOpen = () => {
  const banner = document.getElementById(ONETRUST_BANNER_ID);
  if (!banner) {
    return false;
  }

  const style = getComputedStyle(banner);
  return (
    style.display !== "none" &&
    style.visibility !== "hidden" &&
    style.opacity !== "0"
  );
};

export function Provider({ children }) {
  const [isReady, setIsReady] = React.useState(false);
  const [isBlocked, setIsBlocked] = React.useState();
  const [isBannerOpen, setIsBannerOpen] = React.useState();
  const checkInterval = 2000;

  /**
   * keep waiting till OneTrust is ready.
   * This is necessary because even when the oneTrust loads it still fetches other scripts.
   * and OneTrust will not be available until those scripts are available.
   */
  useInterval(
    () => {
      if (
        window.OneTrust &&
        window.OnetrustActiveGroups &&
        window.OnetrustActiveGroups.length > 2 // Active groups will be more than two when OneTrust is ready.
      ) {
        setIsReady(true);
        setIsBlocked(false);
      }
    },
    isReady || isBlocked ? null : checkInterval,
  );

  useInterval(
    () => {
      // When OneTrust is not available and OnetrustActiveGroups is available, it means OneTrust is blocked.
      if (!window.OneTrust && window.OnetrustActiveGroups) {
        setIsBlocked(true);
      }
    },
    isBlocked !== undefined || isReady ? null : checkInterval,
  );

  React.useEffect(() => {
    const observerCallback = () => {
      setIsBannerOpen(isOneTrustBannerOpen());
    };

    const observer = new MutationObserver(observerCallback);

    if (isReady && !isBlocked) {
      const banner = document.getElementById(ONETRUST_BANNER_ID);
      if (banner) {
        observer.observe(banner, {
          attributes: true,
          attributeFilter: ["style"],
          childList: false,
          subtree: false,
        });
      }

      observerCallback();
    }

    return () => {
      observer.disconnect();
    };
  }, [isReady, isBlocked]);

  return (
    <OneTrustContext.Provider value={{ isReady, isBlocked, isBannerOpen }}>
      {children}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_SCRIPT_KEY}
        strategy="lazyOnload"
        onError={() => setIsBlocked(true)}
        onLoad={() => {
          setTimeout(() => {
            if (!window.OneTrust) {
              setIsBlocked(true);
            }
          }, checkInterval); // Check a bit later to give time for dependent scripts to load
        }}
      />
    </OneTrustContext.Provider>
  );
}

export function useSDKStatus() {
  const { isReady, isBlocked, isBannerOpen } =
    React.useContext(OneTrustContext);
  return {
    isReady,
    isBlocked,
    isBannerOpen,
  };
}

export function useConsentStatus(categoryId) {
  const [consentStatus, setConsentStatus] = React.useState(
    CONSENT_NOT_COLLECTED,
  );
  const { isReady } = useSDKStatus();
  React.useEffect(() => {
    let isCurrent = true;
    if (isReady) {
      setConsentStatus(getConsentStatus(categoryId));

      window.OptanonWrapper = () => {
        if (isCurrent) {
          setConsentStatus(getConsentStatus(categoryId));
        }
      };
    }

    return () => {
      isCurrent = false;
    };
  }, [categoryId, isReady]);

  return consentStatus;
}

export function showPreferenceCenter() {
  if (window.OneTrust && window.OnetrustActiveGroups) {
    window.OneTrust.ToggleInfoDisplay();
  }
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
