export default ({ get, post, checkStatus, toJSON }) => ({
  getGameDetails: (id) =>
    get(`universe/v1/games/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/games/:game_id",
        }),
      )
      .then(toJSON),
  getGameState: (classroomId, gameId) =>
    get(`api/v2/game_state/${gameId}?classroom_id=${classroomId}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "api/v2/game_state/:game_id?:classroomId",
        }),
      )
      .then(toJSON),
  saveGameState: (gameId, body) =>
    post(`api/v2/game_state/${gameId}/save`, { body })
      .then((response) =>
        checkStatus({
          response,
          method: "POST",
          endpoint: "api/v2/game_state/:game_id/save",
        }),
      )
      .then(toJSON),
});
