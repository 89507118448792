import { useMutation } from "@tanstack/react-query";
import { camelizeKeys } from "humps";
import { useRouter } from "next/router";

import api from "../api";

const client = api();

export default function useResetPassword() {
  const router = useRouter();
  const { resetToken } = camelizeKeys(router.query);
  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
    error,
  } = useMutation((password) => client.resetPassword(password, resetToken));

  return { resetPassword, isLoading, isSuccess, error };
}
