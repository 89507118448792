import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useAdProductsQuery() {
  const {
    data: adProducts,
    isLoading: isAdProductsLoading,
    isError: adProductsError,
  } = useQuery([QUERY_KEYS.AD_PRODUCTS], client.getAdProducts);

  return { adProducts, isAdProductsLoading, adProductsError };
}
