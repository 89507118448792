import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useSearchFiltersQuery({ options = {} } = {}) {
  const oneDay = 1000 * 60 * 60 * 24;
  const {
    data: searchFilters,
    isLoading: isSearchFiltersLoading,
    error: searchFiltersError,
  } = useQuery([QUERY_KEYS.SEARCH_FILTERS], client.getSearchFilters, {
    staleTime: oneDay,
    ...options,
  });

  return { searchFilters, isSearchFiltersLoading, searchFiltersError };
}
