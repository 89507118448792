import React from "react";
import PropTypes from "prop-types";

import AgeGateBox from "../AgeGateBox";
import Pressable from "../Pressable";
import Modal from "../Modal";

export default function AgeGatedPressable({
  children,
  disabled,
  className,
  onPress,
  onAgeGateFailed,
}) {
  const [showAgeGate, setShowAgeGate] = React.useState(false);
  const [locked, setLocked] = React.useState(false);

  const onAgeGateModalClose = () => {
    setShowAgeGate(false);
  };

  const onAgeGatePassed = async () => {
    await onPress();
    onAgeGateModalClose();
  };

  return (
    <Pressable
      onPress={() => setShowAgeGate(true)}
      className={className}
      disabled={disabled}
      buttonType="button"
    >
      {showAgeGate ? (
        <Modal
          isOpen={showAgeGate}
          isDismissable={false}
          onClose={onAgeGateModalClose}
        >
          <div className="md:w-screen-xs p-md bg-white rounded-lg">
            <AgeGateBox
              title={locked ? "Sorry, buddy!" : "Are you a grown up?"}
              description={
                locked
                  ? "You need a grown up to help you."
                  : "If not, get one to help you access this section. It's for grown ups only!"
              }
              locked={locked}
              autoFocus={true}
              onSuccess={onAgeGatePassed}
              onCancel={onAgeGateModalClose}
              onLocked={() => {
                setLocked(true);
                onAgeGateFailed();
              }}
            />
          </div>
        </Modal>
      ) : null}
      {children}
    </Pressable>
  );
}

AgeGatedPressable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onPress: PropTypes.func,
  onAgeGateFailed: PropTypes.func,
};

AgeGatedPressable.defaultProps = {
  disabled: false,
  className: undefined,
  onPress: () => {},
  onAgeGateFailed: () => {},
};
