export default ({ get, checkStatus, toJSON }) => ({
  getContentTags: () =>
    get("universe/v1/content_tags")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getContentTag: (id) =>
    get(`universe/v1/content_tags/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/content_tags/:content_tag_id",
        }),
      )
      .then(toJSON),
  getContentTagVideos: (id) =>
    get(`universe/v1/content_tags/${id}/videos`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/content_tags/:content_tag_id/videos",
        }),
      )
      .then(toJSON),
  getContentTagPrintables: (id) =>
    get(`universe/v1/content_tags/${id}/printables`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/content_tags/:content_tag_id/printables",
        }),
      )
      .then(toJSON),
  getContentTagBlogPosts: (id) =>
    get(`universe/v1/content_tags/${id}/blog_posts`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/content_tags/:content_tag_id/blog_posts",
        }),
      )
      .then(toJSON),
  getFeaturedContent: (id) =>
    get(`universe/v1/content_tags/${id}/featured_content`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/content_tags/:content_tag_id/featured_content",
        }),
      )
      .then(toJSON),
  getContentTagGames: (id) =>
    get(`universe/v1/content_tags/${id}/games`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/content_tags/:content_tag_id/games",
        }),
      )
      .then(toJSON),
});
