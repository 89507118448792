import React from "react";
import PropTypes from "prop-types";
import {
  useTooltip,
  useTooltipTrigger,
  OverlayContainer,
  useOverlayPosition,
  mergeProps,
} from "react-aria";
import { useTooltipTriggerState } from "react-stately";
import { FocusableProvider } from "@react-aria/focus";
import { AnimatePresence, motion } from "framer-motion";

const missingProvider = "Component must be used within <Tooltip.Root>";
const TooltipContext = React.createContext({
  get triggerRef() {
    throw new Error(missingProvider);
  },
  get tooltipProps() {
    throw new Error(missingProvider);
  },
  get tooltipState() {
    throw new Error(missingProvider);
  },
});
TooltipContext.displayName = "TooltipContext";

function ContentInner({ offset, crossOffset, placement, children, className }) {
  const overlayRef = React.useRef(null);

  const {
    tooltipState,
    triggerRef,
    tooltipProps: triggerTooltipProps,
    arrow,
  } = React.useContext(TooltipContext);

  const {
    overlayProps,
    arrowProps,
    placement: appliedPlacement,
  } = useOverlayPosition({
    placement,
    targetRef: triggerRef,
    overlayRef,
    isOpen: tooltipState.isOpen,
    containerPadding: 0,
    offset,
    crossOffset,
  });

  const { tooltipProps } = useTooltip(
    { isOpen: tooltipState.isOpen, ...overlayProps },
    tooltipState,
  );

  return (
    <motion.div
      {...mergeProps(triggerTooltipProps, tooltipProps)}
      ref={overlayRef}
      style={overlayProps.style}
      className={className}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.2, ease: "easeOut" },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.1, ease: "easeIn" },
      }}
    >
      {children}

      {arrow && (
        <div
          className="absolute h-0 w-0 border-l-8 border-r-8  border-solid border-transparent"
          style={{
            ...arrowProps.style,
            ...(appliedPlacement === "bottom"
              ? {
                  top: "-8px",
                  borderBottomWidth: "8px",
                  borderBottomColor: "white",
                }
              : {
                  bottom: "-8px",
                  borderTopWidth: "8px",
                  borderTopColor: "white",
                }),
          }}
        />
      )}
    </motion.div>
  );
}

export function Content({
  offset,
  crossOffset,
  placement,
  children,
  className,
}) {
  const { tooltipState } = React.useContext(TooltipContext);

  return (
    <AnimatePresence>
      {tooltipState.isOpen ? (
        <OverlayContainer>
          <ContentInner
            offset={offset}
            crossOffset={crossOffset}
            placement={placement}
            className={className}
          >
            {children}
          </ContentInner>
        </OverlayContainer>
      ) : null}
    </AnimatePresence>
  );
}

export function Root({ isDisabled, delay, closeDelay, arrow, children }) {
  const ref = React.useRef(null);
  const tooltipState = useTooltipTriggerState({ isDisabled, delay });
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    { isDisabled, delay, closeDelay },
    tooltipState,
    ref,
  );

  return (
    <TooltipContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ triggerRef: ref, tooltipProps, tooltipState, arrow }}
    >
      <FocusableProvider {...triggerProps} ref={ref}>
        {children}
      </FocusableProvider>
    </TooltipContext.Provider>
  );
}

Root.propTypes = {
  isDisabled: PropTypes.bool,
  delay: PropTypes.number,
  closeDelay: PropTypes.number,
  arrow: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Content.propTypes = {
  offset: PropTypes.number,
  crossOffset: PropTypes.number,
  placement: PropTypes.oneOf(["bottom", "top", "left", "right"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

ContentInner.propTypes = Content.propTypes;

Root.defaultProps = {
  isDisabled: false,
  delay: 1500,
  closeDelay: 500,
  arrow: false,
  className: undefined,
};

Content.defaultProps = {
  offset: 0,
  crossOffset: 0,
  className: undefined,
};

ContentInner.defaultProps = Content.defaultProps;
