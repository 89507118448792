import useFavoritesQuery from "./useFavoritesQuery";

export default function useFavoriteQuery({ slug, type }, options = {}) {
  const {
    favorites,
    isFavoritesLoading: isFavoriteLoading,
    favoritesError: favoriteError,
  } = useFavoritesQuery(
    ({ activity, type: activityType }) =>
      activity.id === slug && activityType === type,
    options,
  );

  return { favorite: favorites?.[0], isFavoriteLoading, favoriteError };
}
