import React from "react";
import PropTypes from "prop-types";

const missingMeterProvider = "Component must be used within <Meter.Root>";
const MeterContext = React.createContext({
  get labelProps() {
    throw new Error(missingMeterProvider);
  },
});
MeterContext.displayName = "MeterContext";

export function Root({
  value = 0,
  minValue = 0,
  maxValue = 100,
  className,
  children,
}) {
  const id = React.useId();
  const progress = ((value - minValue) / (maxValue - minValue)) * 100;
  const formattedValue = `${Math.round(progress)}%`;

  const contextValue = React.useMemo(
    () => ({
      progress,
      formattedValue,
      labelProps: {
        id,
      },
    }),
    [progress, formattedValue, id],
  );

  return (
    <MeterContext.Provider value={contextValue}>
      <div
        aria-labelledby={id}
        role="meter progressbar"
        aria-valuenow={progress}
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
        aria-valuetext={formattedValue}
        className={className}
      >
        {children}
      </div>
    </MeterContext.Provider>
  );
}

export function Indicator({ className, children }) {
  const { progress } = React.useContext(MeterContext);

  return (
    <div className={className}>
      {typeof children === "function" ? children({ progress }) : children}
    </div>
  );
}

export function Label({ className }) {
  const { formattedValue, labelProps } = React.useContext(MeterContext);

  return (
    <span {...labelProps} className={className}>
      {formattedValue}
    </span>
  );
}

Root.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Root.defaultProps = {
  value: 0,
  minValue: 0,
  maxValue: 100,
  className: "",
  children: null,
};

Indicator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

Indicator.defaultProps = {
  className: "",
  children: null,
};

Label.propTypes = {
  className: PropTypes.string,
};

Label.defaultProps = {
  className: "",
};
