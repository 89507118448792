import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useChampionsQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: champions,
    isLoading: isChampionsLoading,
    isError: championsError,
  } = useQuery([QUERY_KEYS.CHAMPIONS], client.getChampions, {
    staleTime: oneHour * 4,
    ...options,
  });

  return { champions, isChampionsLoading, championsError };
}
