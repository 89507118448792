import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useContentTagGamesQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: games,
    isLoading: isLoadingGames,
    isError: gamesError,
  } = useQuery(
    [QUERY_KEYS.CONTENT_TAG_GAME_LIST, slug],
    () => client.getContentTagGames(slug),
    {
      staleTime: oneHour * 4,
      ...options,
    },
  );

  return { games, gamesError, isLoadingGames };
}
