import { useQuery } from "@tanstack/react-query";
import { compact } from "lodash";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function usePreRollSlateQuery({ slug, sourceId }, options = {}) {
  const {
    data: preRollSlate,
    isLoading: isPreRollSlateLoading,
    isError: preRollSlateError,
  } = useQuery(
    compact([QUERY_KEYS.AD_PRODUCT_PRE_ROLL_SLATE, slug, sourceId]),
    () => client.getPreRollSlate(slug, sourceId),
    {
      cacheTime: 0,
      retry: false,
      ...options,
    },
  );

  return { preRollSlate, preRollSlateError, isPreRollSlateLoading };
}
