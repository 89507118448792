import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useVideoDetailsQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: videoDetails,
    isLoading: isVideoDetailsLoading,
    isError: videoDetailsError,
  } = useQuery(
    [QUERY_KEYS.VIDEO_DETAILS, slug],
    () => client.getVideoDetails(slug),
    {
      staleTime: oneHour * 4,
      ...options,
    },
  );

  return { videoDetails, isVideoDetailsLoading, videoDetailsError };
}
