import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { twMerge } from "tailwind-merge";

import styles from "./jwplayer.module.css";
import useOnce from "../../../hooks/useOnce";

class TimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = "TimeoutError";
  }
}

/**
 * Waits for a condition to be met before resolving.
 * @param {Function} conditionFunction - A function that returns a boolean indicating whether the desired condition is met.
 * @param {number} [interval=100] - How often (in milliseconds) to check the condition.
 * @param {number} [timeout=5000] - Maximum time (in milliseconds) to wait for the condition to be met before rejecting.
 */
async function waitFor(conditionFunction, interval = 100, timeout = 5000) {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    const checkCondition = () => {
      if (conditionFunction()) {
        resolve();
      } else if (Date.now() - startTime >= timeout) {
        reject(new TimeoutError("Request timed out"));
      } else {
        setTimeout(checkCondition, interval);
      }
    };

    checkCondition();
  });
}

function loadScriptWithRetry(scriptSrc, maxRetries, retryInterval) {
  let retries = 0;

  function loadScript(onSuccess, onFailure) {
    const script = document.createElement("script");
    script.src = scriptSrc;
    script.crossOrigin = "anonymous";

    script.onload = onSuccess;
    script.onerror = () => {
      retries += 1;
      if (retries > maxRetries) {
        onFailure(new Error("Failed to load script"));
      } else {
        setTimeout(() => {
          loadScript(onSuccess, onFailure);
        }, retryInterval);
      }
      document.head.removeChild(script);
    };
    document.head.appendChild(script);
  }

  return new Promise((resolve, reject) => {
    loadScript(resolve, reject);
  });
}

const defaultOptions = {
  aspectratio: "16:9",
  autoPause: {
    viewability: false,
  },
  captions: {
    backgroundColor: "#000000",
    backgroundOpacity: 75,
    color: "#FFFFFF",
    edgeStyle: "none",
    fontFamily: "sans-serif",
    fontOpacity: 100,
    fontSize: 15,
    windowColor: "#000000",
    windowOpacity: 0,
  },
  cast: {},
  controls: true,
  displayHeading: false,
  displaydescription: false,
  displaytitle: false,
  floating: {
    mode: "never",
  },
  generateSEOMetadata: true,
  height: 360,
  include_compatibility_script: false,
  interactive: false,
  intl: {
    en: {},
  },
  logo: {
    hide: false,
    position: "top-right",
  },
  mute: false,
  pipIcon: "disabled",
  playbackRateControls: true,
  preload: "metadata",
  repeat: false,
  skin: {
    controlbar: {
      background: "rgba(0,0,0,0)",
      icons: "rgba(255,255,255,0.8)",
      text: "#F2F2F2",
    },
    menus: {
      background: "#333333",
      text: "rgba(255,255,255,0.8)",
      textActive: "#FFFFFF",
    },
    timeslider: {
      progress: "#7328ff", // Gonoodle purple represents purple in tailwind
      rail: "#fff",
    },
    tooltips: {
      background: "#FFFFFF",
      text: "#000000",
    },
  },
  width: "100%",
  base: process.env.NEXT_PUBLIC_JW_PLAYER_BASE_URL,
  related: {
    displayMode: "shelf",
    onclick: "link",
  },
};

const JWPlayer = React.forwardRef(function Player(
  {
    playbackId,
    playlistId,
    adTagUrl,
    autoPlay,
    stretching = "uniform",
    hideControls = false,
    children,
    onPlay = () => {},
    onPause = () => {},
    onComplete = () => {},
    onPlayAttempt = () => {},
    onFullscreenChange = () => {},
    onPlayerReady = () => {},
    onAdStart = () => {},
    onAdComplete = () => {},
    onError = () => {},
  },
  ref,
) {
  const [player, setPlayer] = React.useState();
  const [adStarted, setAdStarted] = React.useState(false);
  const containerRef = React.useRef(null);
  const onSetupErrorRef = React.useRef(useOnce(onError));
  const onPlayerReadyRef = React.useRef(onPlayerReady);
  const onPlayAttemptRef = React.useRef(onPlayAttempt);
  onPlayAttemptRef.current = onPlayAttempt;

  const primitiveOnPlay = React.useCallback(
    ({ callback }) => {
      const attempt = onPlayAttemptRef.current();

      if (attempt === false) {
        player.pause();
      } else {
        callback();
      }
    },
    [player],
  );

  React.useImperativeHandle(
    ref,
    () => ({
      pause: () => player && player.pause(),
      play: () => player && player.play(),
      getState: () => player && player.getState(),
      onPlay: (callback) => {
        if (!isFunction(callback)) return undefined;

        const onPlayHandler = ({ playReason }) => {
          primitiveOnPlay({ playReason, callback });
        };

        if (isFunction(player?.on)) player.on("play", onPlayHandler);

        return () =>
          isFunction(player?.off) && player.off("play", onPlayHandler);
      },
      onPause: (callback) => {
        if (!isFunction(callback)) return undefined;

        const handlePause = () => {
          callback();
        };

        if (isFunction(player?.on)) player.on("pause", handlePause);

        return () =>
          isFunction(player?.off) && player.off("pause", handlePause);
      },
      onComplete: (callback) => {
        if (!isFunction(callback)) return undefined;

        const handleComplete = () => {
          callback();
        };

        if (isFunction(player?.on)) player.on("complete", handleComplete);

        return () =>
          isFunction(player?.off) && player.off("complete", handleComplete);
      },
      onTimeUpdate: (callback) => {
        if (!isFunction(callback)) return undefined;

        const handleTimeUpdate = () => {
          callback({
            position: player.getPosition(),
          });
        };

        if (isFunction(player?.on)) player.on("time", handleTimeUpdate);

        return () =>
          isFunction(player?.off) && player.off("time", handleTimeUpdate);
      },
    }),
    [player, primitiveOnPlay],
  );

  React.useEffect(() => {
    let jwplayerInstance = null;

    const setupPlayer = async () => {
      let wasLoaded = true;

      if (!window.jwplayer) {
        wasLoaded = false;
        try {
          await loadScriptWithRetry(
            process.env.NEXT_PUBLIC_JW_LIBRARY_SCRIPT,
            3,
            1000,
          );
        } catch (e) {
          onSetupErrorRef.current(e);
          return;
        }
      }

      try {
        jwplayerInstance = window.jwplayer(containerRef.current).setup({
          ...defaultOptions,
          playlist: `//content.jwplatform.com/v2/media/${playbackId}`,
          advertising: {
            client: "vast",
            tag: adTagUrl,
            autoplayadsmuted: false,
            outstream: false,
          },
          autostart: autoPlay,
          stretching,
        });
      } catch (error) {
        onSetupErrorRef.current({
          message: `Player failed to setup, ${error?.message}. Attached container ${containerRef?.current}`,
          code: error?.code,
          type: "setupError",
        });
      }

      if (playlistId) {
        jwplayerInstance.setPlaylistItemCallback((item) => {
          // eslint-disable-next-line no-param-reassign
          item.recommendations = `//content.jwplatform.com/v2/playlists/${playlistId}`;
        });
      }

      if (!wasLoaded) {
        try {
          jwplayerInstance.once("ready", () => {
            setPlayer(jwplayerInstance);
            onPlayerReadyRef.current();
          });
        } catch (error) {
          const message = `${error.message} - jwplayerInstance = ${jwplayerInstance}`;
          onSetupErrorRef.current({
            message,
            code: error?.code,
            type: "setupError",
          });
        }
      } else {
        try {
          // If the library was already loaded, we need to wait for the player to be ready.
          // As ready event might have already been fired.
          await waitFor(() => jwplayerInstance.isReady());
          setPlayer(jwplayerInstance);
          onPlayerReadyRef.current();
        } catch (error) {
          const isTimeoutError = error instanceof TimeoutError;
          const errorMessage = isTimeoutError
            ? `Timeout while waiting for player to get ready`
            : "Unexpected error while waiting for player to get ready";

          onSetupErrorRef.current({
            message: errorMessage,
            code: jwplayerInstance?.getConfig()?.error?.code || "timeOut",
            type: "setupError",
          });
        }
      }

      try {
        jwplayerInstance.once(
          "setupError",
          (e = { message: "unknown setup error" }) => {
            onSetupErrorRef.current(e);
          },
        );
      } catch (error) {
        const message = `${error.message} - jwplayerInstance = ${jwplayerInstance}`;
        onSetupErrorRef.current({
          message,
          code: error?.code,
          type: "setupError",
        });
      }
    };

    setupPlayer();

    return () => {
      if (isFunction(jwplayerInstance?.remove)) {
        setPlayer(undefined);
        setAdStarted(false);

        jwplayerInstance.remove();
        window.jwplayer = undefined;
      }
    };

    /**
     * It is safe to ignore the eslint warning below,
     * because we want to run the player setup only when the playbackId changes,
     * as changes in other props will require restarting the playback.
     */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackId]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isFunction(player?.setConfig)) return;
      const { canAutoplay, autostart: autostartConfig } = player.getConfig();

      if (autoPlay === false && autostartConfig === true) {
        player.setConfig({ autostart: false });
      } else if (
        autoPlay === true &&
        autostartConfig === false &&
        canAutoplay !== "autoplayDisabled"
      ) {
        player.setConfig({ autostart: true });

        try {
          player.play();
        } catch (e) {
          /* empty */
        }
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [player, autoPlay]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isFunction(player?.setConfig)) {
        player.setConfig({ stretching });
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [player, stretching]);

  React.useEffect(() => {
    const onPlayHandler = ({ playReason }) => {
      primitiveOnPlay({ playReason, callback: onPlay });
    };

    if (isFunction(player?.on)) player.on("play", onPlayHandler);

    return () => isFunction(player?.off) && player.off("play", onPlayHandler);
  }, [player, onPlay, primitiveOnPlay]);

  React.useEffect(() => {
    const onPauseHandler = () => {
      onPause();
    };

    if (isFunction(player?.on)) player.on("pause", onPauseHandler);
    return () => isFunction(player?.off) && player.off("pause", onPauseHandler);
  }, [player, onPause]);

  React.useEffect(() => {
    const onCompleteHandler = () => {
      onComplete();
    };

    if (isFunction(player?.on)) player.on("complete", onCompleteHandler);
    return () =>
      isFunction(player?.off) && player.off("complete", onCompleteHandler);
  }, [player, onComplete]);

  React.useEffect(() => {
    const onFullscreenHandler = ({ fullscreen }) => {
      onFullscreenChange(fullscreen);
    };

    if (isFunction(player?.on)) player.on("fullscreen", onFullscreenHandler);
    return () =>
      isFunction(player?.off) && player.off("fullscreen", onFullscreenHandler);
  }, [player, onFullscreenChange]);

  React.useEffect(() => {
    const onAdPlayHandler = () => {
      const attempt = onPlayAttemptRef.current();

      if (attempt === false) {
        player.pause();
      } else if (adStarted === false) {
        setAdStarted(true);
        onAdStart();
      }
    };

    if (isFunction(player?.on)) player.on("adPlay", onAdPlayHandler);
    return () =>
      isFunction(player?.off) && player.off("adPlay", onAdPlayHandler);
  }, [adStarted, onAdStart, player]);

  React.useEffect(() => {
    const onAdCompleteHandler = () => {
      onAdComplete();
    };

    if (isFunction(player?.on)) player.on("adComplete", onAdCompleteHandler);
    return () =>
      isFunction(player?.off) && player.off("adComplete", onAdCompleteHandler);
  }, [player, onAdComplete]);

  React.useEffect(() => {
    const onErrorHandler = (e = { message: "unknown playback error" }) => {
      onError(e);
    };

    if (isFunction(player?.on)) {
      player.on("error", onErrorHandler);
    }

    return () => isFunction(player?.off) && player.off("error", onErrorHandler);
  }, [player, onError]);

  return (
    <div className={styles.jwPlayerWrapper}>
      <div
        className={twMerge(
          "relative gonoodle-jwplayer-container",
          hideControls && "gonoodle-jwplayer-no-controls",
        )}
      >
        <div
          className="w-full h-full aspect-w-16 aspect-h-9 bg-black"
          ref={containerRef}
        >
          <div className="flex justify-center items-center w-full h-full">
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-purple"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading</span>
            </div>
          </div>
        </div>

        {player?.getContainer &&
          ReactDOM.createPortal(
            <div className="absolute inset-0 z-10 overflow-hidden pointer-events-none">
              {children}
            </div>,
            player.getContainer(),
          )}
      </div>
    </div>
  );
});

JWPlayer.propTypes = {
  playbackId: PropTypes.string.isRequired,
  playlistId: PropTypes.string,
  adTagUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  stretching: PropTypes.oneOf(["uniform", "exactfit", "fill", "none"]),
  hideControls: PropTypes.bool,
  children: PropTypes.node,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onComplete: PropTypes.func,
  onPlayAttempt: PropTypes.func,
  onFullscreenChange: PropTypes.func,
  onPlayerReady: PropTypes.func,
  onAdStart: PropTypes.func,
  onAdComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default JWPlayer;
