export default ({ get, checkStatus, toJSON }) => ({
  getHeroUnitCarousel: (id) =>
    get(`universe/v2/hero_unit_carousels/search?hero_unit_carousel_ids=[${id}]`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint:
            "universe/v2/hero_unit_carousels/search?:hero_unit_carousel_ids",
        }),
      )
      .then(toJSON),
});
