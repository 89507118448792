import React from "react";

import useProfile from "../useProfile";

export default function useClaimedBadges() {
  const {
    profile: { claimedBadges: undecoratedClaimedBadges = [] } = {},
  } = useProfile();

  const claimedBadges = React.useMemo(
    () =>
      undecoratedClaimedBadges
        .map((badge) => ({
          ...badge,
          isEarned: false,
          isClaimed: true,
        }))
        .sort(
          (a, b) => new Date(a.publishStartsAt) - new Date(b.publishStartsAt),
        ),
    [undecoratedClaimedBadges],
  );

  return {
    claimedBadges,
  };
}
