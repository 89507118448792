import Joi from "joi";

import { PAGES, PAGES_TYPES } from "../constants";

export const newsletterSignUp = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});
