import React from "react";
import PropTypes from "prop-types";

export default function ContentTag({ text, slug, onClick, blog }) {
  return (
    <button
      type="button"
      onClick={() => onClick(slug)}
      className={`inline-flex items-center my-xs py-xs px-sm gn-text-xs rounded-full mr-xs focus:outline-none ${
        blog
          ? "bg-gray-100 text-black hover:text-purple hover:bg-gray-200"
          : "bg-gray-800 text-white hover:text-black hover:bg-mint"
      }`}
    >
      {text}
    </button>
  );
}

ContentTag.propTypes = {
  text: PropTypes.string,
  slug: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  blog: PropTypes.bool,
};

ContentTag.defaultProps = {
  text: "Blazer Fresh",
  slug: "blazer-fresh",
  blog: false,
};
