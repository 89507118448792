import Joi from "joi";
import { PAGES, PAGES_TYPES, SECTIONS_TYPES } from "../constants";

export const champProfileViewed = Joi.object({
  champName: Joi.string().required(),
  sourcePage: Joi.string(),
  sourcePageType: Joi.string().valid(
    "direct",
    ...Object.values(PAGES),
    ...Object.values(PAGES_TYPES),
  ),
  sourceName: Joi.string(),
  sourceElement: Joi.string(),
});

export const champRandomizerUsed = Joi.object({
  champName: Joi.string().required(),
  sourceName: Joi.string().required(),
  sourceElement: Joi.string()
    .valid("direct", ...Object.values(SECTIONS_TYPES))
    .required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  gradeId: Joi.number().allow(null),
  schoolId: Joi.number().allow(null),
  size: Joi.number().allow(null),
  name: Joi.string().allow(null),
});

export const champSelected = Joi.object({
  champName: Joi.string().required(),
  champRandomizerUsed: Joi.boolean().required(),
  sourceName: Joi.string().required(),
  sourceElement: Joi.string()
    .valid("direct", ...Object.values(SECTIONS_TYPES))
    .required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  gradeId: Joi.number().required(),
  schoolId: Joi.number().allow(null),
  size: Joi.number().allow(null),
  name: Joi.string().required(),
  profileType: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const unregisteredChampAchievement = Joi.object({
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const reachChampAchievement = Joi.object({
  champName: Joi.string().required(),
  level: Joi.string().required(),
});

export const champAchievementPrinted = Joi.object({
  champName: Joi.string().required(),
  level: Joi.string().required(),
});

export const newChampSelected = Joi.object({
  champName: Joi.string().required(),
  prevChampName: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const levelUpVideo = Joi.object({
  newChampLevel: Joi.string().required(),
});

export const levelUpVideoSkipped = Joi.object({
  newChampLevel: Joi.string().required(),
});
