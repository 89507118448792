import useBadgesQuery from "../useBadgesQuery";
import useFilteredBadges from "../useFilteredBadges";
import { BADGES_TYPES } from "../../constants";

export default function useRegularBadgesQuery() {
  const {
    badges = [],
    isBadgesLoading: isRegularBadgesLoading,
    badgesError: regularBadgesError,
  } = useBadgesQuery();

  const { filteredBadges: regularBadges } = useFilteredBadges({
    badges,
    types: [
      BADGES_TYPES.EVERGREEN,
      BADGES_TYPES.FEATURED,
      BADGES_TYPES.LIMITED,
    ],
  });

  return { regularBadges, isRegularBadgesLoading, regularBadgesError };
}
