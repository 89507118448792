import { useMutation } from "@tanstack/react-query";

import api from "../api";

const client = api();

export default function useValidateEmail() {
  const { mutateAsync: validateEmail, isLoading } = useMutation(
    (email) => client.validateEmail(email),
    {
      retry: false,
    },
  );
  return { validateEmail, isLoading };
}
