export default ({ get, checkStatus, toJSON }) => ({
  getVideoDetails: (id) =>
    get(`universe/v1/videos/${id}`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/videos/:video_id",
        }),
      )
      .then(toJSON),
  getHeroVideo: (id) =>
    get(`/universe/v1/hero_video/search?video_ids=[${id}]`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "/universe/v1/hero_video/search?:video_ids",
        }),
      )
      .then(toJSON),
  getDefaultHeroVideo: () =>
    get("universe/v1/hero_video/default")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getRecentlyPublishedVideos: () =>
    get("universe/v1/newest_videos")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getChampiverseVideos: () =>
    get("universe/v1/champiverse_videos")
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  getRecommendedVideos: (slug) =>
    get(`universe/v1/videos/${slug}/recommended`)
      .then((response) =>
        checkStatus({
          response,
          method: "GET",
          endpoint: "universe/v1/videos/:slug/recommended",
        }),
      )
      .then(toJSON),
});
