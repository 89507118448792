import React from "react";

import useProfile from "../useProfile";

export default function useEarnedBadges() {
  const {
    profile: { earnedBadges: undecoratedEarnedBadges = [] } = {},
  } = useProfile();

  const earnedBadges = React.useMemo(
    () =>
      undecoratedEarnedBadges
        .map((badge) => ({
          ...badge,
          isEarned: true,
          isClaimed: false,
        }))
        .sort(
          (a, b) => new Date(a.publishStartsAt) - new Date(b.publishStartsAt),
        ),
    [undecoratedEarnedBadges],
  );

  return {
    earnedBadges,
  };
}
