import React from "react";
import PropTypes from "prop-types";

import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from "../Button";

export default function PressCard({ title, date, onClick, buttonText }) {
  return (
    <div
      key={title}
      className="flex bg-gray-800 flex-col rounded-lg shadow-lg overflow-hidden"
    >
      <div className="flex-1 p-sm flex flex-col justify-between">
        <div className="flex-1">
          <p className="gn-headline-sm text-white">{title}</p>
          <p className="gn-text mt-xs text-base text-white">{date}</p>
        </div>
        <div className="mt-sm flex items-center">
          <Button variant={BUTTON_VARIANTS.lightOutline} size={BUTTON_SIZES.md} onPress={onClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}
PressCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

PressCard.defaultProps = {
  title:
    "GoNoodle launches Buena Energía, Spanish resources to keep kids moving at home",
  date: "November 2, 2020",
  buttonText: "via GoNoodle",
};
