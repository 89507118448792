import React from "react";

export default function useFilteredBadges({ badges = [], types = [] } = {}) {
  const filteredBadges = React.useMemo(() => {
    if (!types.length) return badges;

    return badges.filter((badge) =>
      types.some((type) => badge.badgeType === type),
    );
  }, [badges, types]);

  return { filteredBadges };
}
