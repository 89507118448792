import React from "react";
import { find, isEmpty } from "lodash";

import { useUser } from "../contexts/user";
import useCurriculumsQuery from "./useCurriculumsQuery";
import useUserAccessesQuery from "./useUserAccessesQuery";

function getClassroomCurriculum(user) {
  // Find the classroom that has curriculum access and non-empty curriculums
  const classroomWithCurriculum = find(
    user?.classrooms,
    (classroom) =>
      classroom.id === user?.selectedClassroomId &&
      !isEmpty(classroom.curriculumAccesses) &&
      !isEmpty(classroom.curriculums),
  );

  if (!classroomWithCurriculum) {
    return undefined;
  }

  const selectedCurriculumId =
    classroomWithCurriculum.curriculumAccesses?.[0]?.curriculums?.[0];

  return find(
    classroomWithCurriculum.curriculums.allCurriculums,
    (curriculumClassroom) => curriculumClassroom.id === selectedCurriculumId,
  );
}

function getFirstAccessibleCurriculum(curriculums = [], userAccesses = []) {
  // Find the first accessible curriculum
  const accessibleCurriculum = curriculums.find((curriculum) =>
    userAccesses.some((access) => access.resource.rid === curriculum.pianoRid),
  );

  if (accessibleCurriculum) {
    const { id, name } = accessibleCurriculum;
    return { id, name };
  }

  return undefined;
}

export default function useCurriculum() {
  const { user } = useUser();
  const { curriculums } = useCurriculumsQuery();
  const { userAccesses } = useUserAccessesQuery({
    enabled: user?.features?.piano === true,
  });

  const curriculum = React.useMemo(() => {
    if (!user?.isLoggedIn) {
      return undefined;
    }

    if (!user?.selectedClassroomId) {
      return undefined;
    }

    if (user?.features?.piano && user?.features?.pianoCurriculumAccess) {
      return getFirstAccessibleCurriculum(curriculums, userAccesses);
    }

    return getClassroomCurriculum(user);
  }, [curriculums, user, userAccesses]);

  return curriculum;
}
