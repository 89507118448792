export default function concatenateQueryParams(base, params = {}) {
  let path = `${base}`;

  path += Object.keys(params).reduce((acc, key, i) => {
    const prefix = i === 0 ? `?${acc}` : `${acc}&`;
    if (params[key]) {
      return `${prefix}${key}=${encodeURIComponent(params[key])}`;
    }

    return acc;
  }, "");

  return path;
}
