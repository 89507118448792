import { useEffect, useReducer } from "react";

import usePlayer from "./usePlayer";

const INITIAL_STATE = { completed: false };
const STATES = {
  PLAYING: "playing",
  PAUSED: "paused",
  COMPLETED: "completed",
};
export const ACTIONS = {
  PLAY: "play",
  PAUSE: "pause",
  COMPLETE: "complete",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.PLAY:
      return {
        playing: true,
        paused: false,
        completed: false,
      };
    case ACTIONS.PAUSE:
      return {
        playing: false,
        paused: true,
        completed: false,
      };
    case ACTIONS.COMPLETE:
      return {
        playing: false,
        paused: false,
        completed: true,
      };
    default:
      throw new Error(`Unhandled action type: ${action?.type}`);
  }
}

function usePlaybackState() {
  const player = usePlayer();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    let unsubscribeOnPlay = () => {};
    let unsubscribeOnPause = () => {};
    let unsubscribeOnComplete = () => {};
    const currentPlaybackState = player.getState();

    if (currentPlaybackState === STATES.PLAYING) {
      dispatch({ type: ACTIONS.PLAY });
    } else if (currentPlaybackState === STATES.PAUSED) {
      dispatch({ type: ACTIONS.PAUSE });
    }

    unsubscribeOnPlay = player.onPlay(() => {
      dispatch({ type: ACTIONS.PLAY });
    });

    unsubscribeOnPause = player.onPause(() => {
      dispatch({ type: ACTIONS.PAUSE });
    });

    unsubscribeOnComplete = player.onComplete(() => {
      dispatch({ type: ACTIONS.COMPLETE });
    });

    return () => {
      unsubscribeOnPlay();
      unsubscribeOnPause();
      unsubscribeOnComplete();
    };
  }, [player]);

  return state;
}

function usePlaybackController() {
  const player = usePlayer();

  function dispatch(action) {
    switch (action.type) {
      case ACTIONS.PLAY:
        return player.play();
      case ACTIONS.PAUSE:
        return player.pause();
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return dispatch;
}

export default function usePlayback() {
  return [usePlaybackState(), usePlaybackController()];
}
