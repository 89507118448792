import React from "react";
import { useInView } from "@gonoodle/gn-universe-utils";

import api from "../api";

const client = api();

export function sendImpression(properties, options = { event: "Impression" }) {
  return client
    .postImpressionEvents({
      event: options.event,
      properties,
    })
    .catch(() => {});
}

export default function useImpression({ properties, threshold, enabled }) {
  const [impressed, setImpressed] = React.useState(false);
  const { ref, inView } = useInView({ threshold });

  React.useEffect(() => {
    if (typeof enabled === "undefined" || !!enabled) {
      if (!impressed && inView) {
        client
          .postImpressionEvents({
            event: "Impression",
            properties,
          })
          .catch(() => {});
        setImpressed(true);
      }
    }
  }, [enabled, impressed, inView, properties]);

  return ref;
}
