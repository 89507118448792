import useAdProductsQuery from "./useAdProductsQuery";

export default function useAdProductsOfTypeQuery(type) {
  const {
    adProducts: allAdProducts,
    adProductsError,
    isAdProductsLoading,
  } = useAdProductsQuery();
  return {
    adProducts: allAdProducts?.filter((adProduct) => adProduct.type === type),
    adProductsError,
    isAdProductsLoading,
  };
}
