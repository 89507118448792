export default {
  VIDEO: "Video",
  VIDEOS: "Videos",
  ACTIVITY: "Activity",
  ACTIVITIES: "Activities",
  TOPIC: "Topic",
  CHANNEL: "Channel",
  TAG: "Tag",
  TAGS: "Content Tags",
  CUSTOM_LINK: "Custom Link",
  BLOG_POST: "Blog Post",
  CHARACTER: "character",
  CHAMPION: "champ",
  COMPETENCY: "Competency",
  CALL_TO_ACTION: "CallToAction",
};
