import { generatePath } from "@gonoodle/gn-universe-utils";

import { SPOTLIGHT_TYPES, ROUTE_PATHS } from "../constants";
import { generateSpotlightPathByType } from "../utils/route";

export default function selectSpotlight(spotlight) {
  if (!spotlight) {
    return null;
  }
  const isProgrammedSpotlight = spotlight?.type !== "ChannelContentTag";
  const {
    buttonText,
    destinationType,
    hexColor,
    textTheme,
    url,
    title,
    description,
    content,
    image,
    ...rest
  } = spotlight;

  let urlPath;
  if (!isProgrammedSpotlight || !destinationType) {
    urlPath = generatePath(ROUTE_PATHS.TAGS, {
      id: spotlight.id,
      slug: spotlight.slug,
    });
  } else if (destinationType !== "External Link") {
    urlPath = generateSpotlightPathByType(destinationType, {
      id: content.id,
      slug: content.slug,
    });
  }

  return {
    description,
    buttonText: isProgrammedSpotlight
      ? spotlight.buttonText
      : `Check out ${spotlight.name}`,
    title: isProgrammedSpotlight ? spotlight.title : spotlight.name,
    image: isProgrammedSpotlight
      ? spotlight.image
      : spotlight.images.spotlightImage,
    destinationType,
    isExternal: destinationType === SPOTLIGHT_TYPES.EXTERNAL,
    externalUrl: url,
    urlPath,
    content,
    hexColor,
    textTheme,
    ...rest,
  };
}
