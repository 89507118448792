/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from "prop-types";
import React from "react";
import { twMerge } from "tailwind-merge";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useTextField } from "react-aria";
import { useComposedRefs } from "@gonoodle/gn-universe-utils";

const TextField = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      description,
      errorMessage,
      value,
      required,
      disabled,
      endAdornment,
      type,
      defaultValue,
      autoComplete,
      autoFocus,
      onKeyUp,
      onKeyDown,
      onBlur,
      onChange,
      onInput,
      testId,
      className,
      ...props
    },
    forwardedRef,
  ) => {
    const ref = React.useRef();
    const composedRefs = useComposedRefs(ref, forwardedRef);
    const {
      labelProps,
      inputProps,
      descriptionProps,
      errorMessageProps,
    } = useTextField(
      {
        name,
        label,
        placeholder,
        description,
        errorMessage,
        validationState: errorMessage ? "invalid" : "valid",
        value,
        defaultValue,
        isDisabled: disabled,
        isRequired: required,
        type,
        autoComplete,
        onChange,
        onBlur,
        onKeyUp,
        onKeyDown,
        onInput,
        autoFocus,
        ...props,
      },
      ref,
    );

    const hasError = Boolean(errorMessage);
    const hasAdornment = Boolean(endAdornment);

    return (
      <div>
        <label className="mb-xs block gn-text-sm text-gray-500" {...labelProps}>
          {label}
        </label>
        <div className="relative">
          <input
            {...inputProps}
            ref={composedRefs}
            data-testid={testId}
            className={twMerge(
              "block w-full h-input pl-sm gn-text rounded border-sm border-gray-300 shadow-lightSM",
              `${
                hasError
                  ? "text-danger placeholder-danger border-none ring ring-danger focus:ring focus:ring-danger"
                  : "text-gray-900 placeholder-gray-500 focus:ring focus:ring-purple focus:outline-none focus:border-none"
              }
             `,
              className,
            )}
          />
          {hasAdornment ? (
            <div
              className="absolute inset-y-0 right-md flex items-center h-fit my-auto"
              data-testid="end-adornment"
              aria-hidden="true"
            >
              {endAdornment}
            </div>
          ) : null}
        </div>
        {description ? (
          <div
            {...descriptionProps}
            className="mt-xs gn-text-sm text-gray-500"
            data-test-id="description-text-container"
          >
            {description}
          </div>
        ) : null}

        <div
          {...errorMessageProps}
          className={`mt-xs flex flex-row gap-xs ${!hasError && "hidden"}`}
          data-testid="error-text"
        >
          <ExclamationCircleIcon
            className="h-input-icon text-danger flex-shrink-0"
            aria-hidden="true"
          />
          <div className="gn-text-sm text-danger">{errorMessage}</div>
        </div>
      </div>
    );
  },
);

TextField.displayName = "TextField";

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errorMessage: PropTypes.node,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.element,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  testId: PropTypes.string,
  className: PropTypes.string,
};

TextField.defaultProps = {
  name: undefined,
  label: undefined,
  placeholder: undefined,
  errorMessage: undefined,
  value: "",
  description: undefined,
  required: undefined,
  disabled: undefined,
  endAdornment: undefined,
  autoComplete: undefined,
  defaultValue: undefined,
  type: "text",
  autoFocus: undefined,
  onKeyUp: undefined,
  onKeyDown: undefined,
  onBlur: undefined,
  onChange: undefined,
  onInput: undefined,
  testId: undefined,
  className: "",
};

export default TextField;
