import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { inBrowser } from "@gonoodle/gn-universe-utils";

const enabledStages = ["production", "staging"];

if (
  process.env.NEXT_PUBLIC_REPORT_DEV_ERRORS &&
  JSON.parse(process.env.NEXT_PUBLIC_REPORT_DEV_ERRORS)
) {
  enabledStages.push("development");
}

const BugsnagClient = Bugsnag.start({
  apiKey: inBrowser()
    ? process.env.NEXT_PUBLIC_BUGSNAG_BROWSER_API_KEY
    : process.env.BUGSNAG_SERVER_API_KEY,
  appVersion: process.env.BUILD_ID,
  enabledReleaseStages: enabledStages,
  releaseStage: process.env.DEPLOY_ENV,
  plugins: [new BugsnagPluginReact(React)],
  maxBreadcrumbs: 100,
});

export default BugsnagClient;
