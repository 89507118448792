import Joi from "joi";

import { SECTIONS_TYPES, PAGES, PAGES_TYPES } from "../constants";

export const link = Joi.object({
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceElement: Joi.string()
    .valid(...Object.values(SECTIONS_TYPES))
    .required(),
  title: Joi.string().required(),
  url: Joi.string(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const announcementBannerClicked = Joi.object({
  sourceElement: Joi.string()
    .valid(
      SECTIONS_TYPES.BANNER,
      SECTIONS_TYPES.GROWL,
      SECTIONS_TYPES.NOTIFICATION_CENTER,
    )
    .required(),
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  title: Joi.string().required(),
  gradeId: Joi.number().allow(null),
  schoolId: Joi.number().allow(null),
  size: Joi.number().allow(null),
  partnership: Joi.string().allow(null),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const carouselNavigationClicked = Joi.object({
  sourceElement: Joi.string().required(),
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  title: Joi.string().required(),
  schoolId: Joi.number().allow(null),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const NotificationCenterClicked = Joi.object({
  sourceElement: Joi.string().required(),
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});
