import React from "react";

import useProfile from "./useProfile";
import useChampionsQuery from "./useChampionsQuery";
import useProfileChampionsQuery from "./useProfileChampionsQuery";

export default function useChampions() {
  const { champions, isChampionsLoading, championsError } = useChampionsQuery();

  return { champions, isChampionsLoading, championsError };
}

export function useProfileChampions() {
  const { profile } = useProfile();
  const { champions, isChampionsLoading, championsError } = useChampions();
  const {
    profileChampions: profileUsedChampions,
    isProfileChampionsLoading,
    profileChampionsError,
  } = useProfileChampionsQuery();

  const profileChampions = React.useMemo(
    () =>
      !championsError &&
      Array.isArray(champions) &&
      !profileChampionsError &&
      Array.isArray(profileUsedChampions) &&
      champions.map((champion) => {
        const usedChampion = profileUsedChampions.find(
          ({ champId }) => champId === champion.id,
        );
        const isCurrent =
          profile?.currentClassroomChamp?.champId === usedChampion?.champId;

        return usedChampion
          ? {
              ...usedChampion,
              isCurrent,
              bodyImageSources: {
                "regular@1x": usedChampion.images.full["1x"],
                "regular@2x": usedChampion.images.full["2x"],
                "regular@3x": usedChampion.images.full["3x"],
              },
              headImageSources: {
                "regular@1x": usedChampion.images.headshot["1x"],
                "regular@2x": usedChampion.images.headshot["2x"],
                "regular@3x": usedChampion.images.headshot["3x"],
              },
            }
          : {
              ...champion,
              graduated: false,
              bodyImageSources: {
                "regular@1x": champion.images.phase1.full["1x"],
                "regular@2x": champion.images.phase1.full["2x"],
                "regular@3x": champion.images.phase1.full["3x"],
              },
              headImageSources: {
                "regular@1x": champion.images.phase1.headshot["1x"],
                "regular@2x": champion.images.phase1.headshot["2x"],
                "regular@3x": champion.images.phase1.headshot["3x"],
              },
            };
      }),
    [
      champions,
      championsError,
      profile,
      profileUsedChampions,
      profileChampionsError,
    ],
  );

  return {
    profileChampions,
    isProfileChampionsLoading: isChampionsLoading || isProfileChampionsLoading,
    profileChampionsError: championsError || profileChampionsError,
  };
}
