import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();
const oneHour = 60 * 60 * 1000; // milliseconds in an hour
const oneDay = 24 * oneHour;

export default function useContentTagBlogPostsQuery({ slug }, options = {}) {
  const {
    data: blogPosts,
    isLoading: isLoadingBlogPosts,
    isError: blogPostsError,
  } = useQuery(
    [QUERY_KEYS.CONTENT_TAG_BLOG_LIST, slug],
    () => client.getContentTagBlogPosts(slug),
    {
      staleTime: oneDay,
      ...options,
    },
  );

  return { blogPosts, blogPostsError, isLoadingBlogPosts };
}
