import React from "react";

export default function useTruncatedText() {
  const containerRef = React.useRef();
  const [lineClamp, setLineClamp] = React.useState(0);

  React.useEffect(() => {
    const container = containerRef.current;
    const lineHeight = parseInt(
      window.getComputedStyle(container).lineHeight,
      10,
    );

    const handleResize = () => {
      const newLineClamp = Math.max(
        Math.floor(container.clientHeight / lineHeight),
        0,
      );
      if (newLineClamp !== lineClamp) {
        setLineClamp(newLineClamp);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [lineClamp]);

  return { containerRef, lineClamp };
}
