import { useQueryClient, useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useSurveyMutation(
  { slug, ageGroupId, survey },
  options = {},
) {
  const queryClient = useQueryClient();

  const {
    mutateAsync: completeSurveyAsync,
    isLoading: isSurveyMutationLoading,
    isError: surveyMutationError,
  } = useMutation(
    () =>
      client.completeSurvey({
        ageGroupId,
        survey,
      }),
    {
      ...options,
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERY_KEYS.ELEMENT, slug]);
        options.onSuccess?.();
      },
    },
  );

  return {
    completeSurveyAsync,
    isSurveyMutationLoading,
    surveyMutationError,
  };
}
