import Joi from "joi";
import {
  PAGES,
  PAGES_TYPES,
  SECTIONS,
  SECTIONS_TYPES,
  CONTENT_TYPES,
} from "../constants";

export const blogPostViewed = Joi.object({
  sourceName: Joi.string()
    .valid(...Object.values(SECTIONS))
    .required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceElement: Joi.string()
    .valid("direct", ...Object.values(SECTIONS_TYPES))
    .required(),
  contentType: Joi.string()
    .valid(...Object.values(CONTENT_TYPES))
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const activityViewed = Joi.object({
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid(
      "direct",
      "share",
      ...Object.values(PAGES),
      ...Object.values(PAGES_TYPES),
    )
    .required(),
  sourceElement: Joi.string().required(),
  contentType: Joi.string()
    .valid(...Object.values(CONTENT_TYPES))
    .required(),
  id: Joi.string().required(),
  title: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const leavingGoNoodleBumperViewed = Joi.object({
  sourcePage: Joi.string(),
  sourcePageType: Joi.string(),
  sourceName: Joi.string(),
  sourceElement: Joi.string(),
  title: Joi.string(),
  url: Joi.string().required(),
  optionSelected: Joi.string().required(),
});

export const createAccountFlowEntered = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string()
    .valid("direct", SECTIONS.CREATE_ACCOUNT_BUTTON)
    .required(),
  sourceElement: Joi.string()
    .valid(
      "direct",
      SECTIONS_TYPES.NAV,
      SECTIONS_TYPES.LOGIN,
      SECTIONS_TYPES.RECENTLY_WATCHED_SHELF,
      SECTIONS_TYPES.FAVORITES_SHELF,
      SECTIONS_TYPES.TRANSMOGRIFIER,
    )
    .required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const ageGateFailed = Joi.object({
  sourceElement: Joi.string().valid(SECTIONS_TYPES.CREATE_ACCOUNT).required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const createAccountEmailSubmission = Joi.object({
  newsletterSignup: Joi.bool().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const createAccountProfileSelection = Joi.object({
  profileType: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const CreateAccountProfileExited = Joi.object({
  sourceName: Joi.string()
    .valid(...Object.values(SECTIONS))
    .required(),
});

export const loginFlowEntered = Joi.object({
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  sourceName: Joi.string()
    .valid(
      "direct",
      SECTIONS_TYPES.NAV,
      SECTIONS_TYPES.CREATE_ACCOUNT,
      SECTIONS.LOGIN_BUTTON,
    )
    .required(),
  sourceElement: Joi.string()
    .valid("direct", SECTIONS_TYPES.NAV, SECTIONS_TYPES.CREATE_ACCOUNT)
    .required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const elementViewed = Joi.object({
  curriculumName: Joi.string().required(),
  competencyName: Joi.string().required(),
  chapterName: Joi.string().required(),
  episodeName: Joi.string().required(),
  elementName: Joi.string().required(),
  elementType: Joi.string().required(),
  ageGroup: Joi.string().required(),
  dateOfLastSn: Joi.string().required(),
});

export const announcementBannerShown = Joi.object({
  sourceElement: Joi.string()
    .valid(
      SECTIONS_TYPES.BANNER,
      SECTIONS_TYPES.GROWL,
      SECTIONS_TYPES.NOTIFICATION_CENTER,
    )
    .required(),
  sourceName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  sourcePageType: Joi.string()
    .valid("direct", ...Object.values(PAGES), ...Object.values(PAGES_TYPES))
    .required(),
  title: Joi.string().required(),
  gradeId: Joi.number().allow(null),
  schoolId: Joi.number().allow(null),
  size: Joi.number().allow(null),
  url: Joi.string().allow(""),
  partnership: Joi.string().allow(null),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});
