import React from "react";
import PropTypes from "prop-types";
import {
  transitions,
  positions,
  Provider as ReactAlertProvider,
  useAlert,
} from "react-alert";

function AlertTemplate({ message }) {
  return (
    <div
      aria-live="assertive"
      className="rounded bg-pink shadow-lg px-3 py-3 m-md"
    >
      <p className="text-md font-bold text-white">{message}</p>
    </div>
  );
}

export function Provider({ children, ...options }) {
  return (
    <ReactAlertProvider
      transition={transitions.FADE}
      template={AlertTemplate}
      {...options}
    >
      {children}
    </ReactAlertProvider>
  );
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  position: PropTypes.oneOf(Object.values(positions)),
  timeout: PropTypes.number,
};

Provider.defaultProps = {
  position: positions.TOP_CENTER,
  timeout: 3000,
};

AlertTemplate.propTypes = {
  message: PropTypes.string.isRequired,
};

export { useAlert, positions };
