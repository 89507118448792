import { camelizeKeys, decamelizeKeys } from "humps";

import config from "../config";
import { getReqCookies, setCookies } from "../utils/cookies";
import BugsnagClient from "../utils/bugsnag";
import videos from "./videos";
import user from "./user";
import contentTags from "./contentTags";
import adProducts from "./adProducts";
import printables from "./printables";
import events from "./events";
import activitySession from "./activitySession";
import search from "./search";
import spotlights from "./spotlights";
import shelves from "./shelves";
import favorites from "./favorites";
import champs from "./champs";
import curriculum from "./curriculum";
import layouts from "./layouts";
import onSiteMessages from "./onSiteMessages";
import heroUnits from "./heroUnits";
import singleSignOn from "./singleSignOn";
import heroUnitCarousel from "./heroUnitCarousel";
import games from "./games";
import badges from "./badges";

/**
 * Check the status code of a response. Returns response if status is ok.
 * Throws error otherwise.
 * @param {Object} response - Fetch response
 * @returns {Promise, Error} Fetch response
 */
const checkStatus = ({ response = {}, method = "", endpoint = "" }) => {
  if (response.ok) {
    return response;
  }

  const ignoreApiErrors = [
    "GET universe/v1/games/:game_id returned a 410 status code",
    "GET universe/v1/printables/:printable_id returned a 410 status code",
    "GET universe/v1/videos/:video_id returned a 410 status code",
    "POST /universe/v1/users/validate_email returned a 403 status code",
    "POST /universe/v1/passwords/forgot returned a 404 status code",
  ];
  const endpointUrl = response?.url?.replace(
    process.env.NEXT_PUBLIC_API_BASEURL,
    "",
  );
  // If the wording in errorName is changed, the ignoreApiErrors texts should be updated too
  const errorName = `${method} ${endpoint || endpointUrl} returned a ${
    response?.status
  } status code`;

  if (!ignoreApiErrors.includes(errorName)) {
    BugsnagClient.notify({
      name: errorName,
      message: `This Endpoint errored with status message: ${
        response?.statusText || "empty 🤷‍♂️"
      } when calling ${endpointUrl}.`,
    });
  }

  const error = new Error(response.statusText);
  error.code = response.status;
  error.response = response;
  throw error;
};

/**
 * Create function to fire an API request using fetch.
 * @param {Object} ctx - Context object
 * @param {Object} ctx.req - Request object
 * @param {Object} ctx.res - Response object
 * @returns {(method: String, uri: String, options: Object) => Promise}
 */
const request = ({ req, res } = {}) => (method, uri, options = {}) => {
  const {
    headers: defaultHeaders,
    includeClientToken,
    body,
    ...rest
  } = options;

  const endpoint = `${config.API_BASEURL}/${uri}`;

  // Assigns the appropriate headers
  let headers = {
    ...defaultHeaders,
    "GN-Device-Platform": "Universe Web",
  };

  if (includeClientToken) {
    headers = {
      ...headers,
      Authorization: `bearer ${config.UNIVERSE_WEB_SECRET_KEY}`,
    };
  }

  if (req) {
    headers = {
      ...headers,
      cookie: getReqCookies(req),
    };
  }

  if (req && req.headers["user-agent"]) {
    headers = {
      ...headers,
      "User-Agent": req.headers["user-agent"],
    };
  }

  // forward cloud trace fields
  if (req && req.headers["x-cloud-trace-context"]) {
    headers = {
      ...headers,
      "X-Cloud-Trace-Context": req.headers["x-cloud-trace-context"],
    };
  }

  // for the Next.js backend calls to the api this will forward client ip
  // this helps with the rack-attack api calls
  if (req && req.headers["x-forwarded-for"]) {
    headers = {
      ...headers,
      "X-Forwarded-For": req.headers["x-forwarded-for"],
    };
  }

  headers = {
    ...headers,
    "content-type": "application/json",
  };

  return fetch(endpoint, {
    credentials: "include",
    method,
    headers,
    body: JSON.stringify(decamelizeKeys(body)),
    ...rest,
  }).then(setCookies({ req, res }));
};

/**
 * Convert response to JSON.
 * @param {Object} response - Fetch response object.
 * @returns {Object} Converted body to JSON.
 */
const toJSON = async (response) => {
  // A 204 response is terminated by the first empty line after the header fields because it cannot contain a message body. So we can not call response.json().
  const json = response.status !== 204 ? await response.json() : null;
  return camelizeKeys(json);
};

const validateJSON = (schema) => async (json) => {
  await schema.validateAsync(json);
  return json;
};

/**
 * A utility for interfacing with the API.
 * @param {Object} ctx - Context object.
 * @returns {Object} Object of methods for API
 */
export default (ctx) => {
  const requestWithCookie = request(ctx);
  const restfulMethods = {
    del: (uri, options) => requestWithCookie("DELETE", uri, options),
    get: (uri, options) => requestWithCookie("GET", uri, options),
    patch: (uri, options) => requestWithCookie("PATCH", uri, options),
    post: (uri, options) => requestWithCookie("POST", uri, options),
    put: (uri, options) => requestWithCookie("PUT", uri, options),
  };
  const helpers = {
    checkStatus,
    toJSON,
    validateJSON,
  };

  return {
    ...videos({ ...restfulMethods, ...helpers }),
    ...user({ ...restfulMethods, ...helpers }),
    ...contentTags({ ...restfulMethods, ...helpers }),
    ...adProducts({ ...restfulMethods, ...helpers }),
    ...printables({ ...restfulMethods, ...helpers }),
    ...events({ ...restfulMethods, ...helpers }),
    ...activitySession({ ...restfulMethods, ...helpers }),
    ...search({ ...restfulMethods, ...helpers }),
    ...spotlights({ ...restfulMethods, ...helpers }),
    ...shelves({ ...restfulMethods, ...helpers }),
    ...favorites({ ...restfulMethods, ...helpers }),
    ...champs({ ...restfulMethods, ...helpers }),
    ...curriculum({ ...restfulMethods, ...helpers }),
    ...layouts({ ...restfulMethods, ...helpers }),
    ...onSiteMessages({ ...restfulMethods, ...helpers }),
    ...heroUnits({ ...restfulMethods, ...helpers }),
    ...singleSignOn({ ...restfulMethods, ...helpers }),
    ...heroUnitCarousel({ ...restfulMethods, ...helpers }),
    ...games({ ...restfulMethods, ...helpers }),
    ...badges({ ...restfulMethods, ...helpers }),
  };
};
