import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

const contentTypeToEndpoint = {
  [CONTENT_TYPES.ACTIVITY]: "printables",
  [CONTENT_TYPES.VIDEO]: "videos",
};

export default ({ get, post, del, checkStatus, toJSON }) => ({
  getFavorites: () =>
    get(`universe/v1/favorites`)
      .then((response) => checkStatus({ response, method: "GET" }))
      .then(toJSON),
  favorite: ({ type, id }) =>
    post(`universe/v1/${contentTypeToEndpoint[type]}/${id}/favorite`).then(
      (response) =>
        checkStatus({
          response,
          method: "POST",
          endpoint: "universe/v1/:content_type/:content_id/favorite",
        }),
    ),
  unfavorite: ({ type, id }) =>
    del(`universe/v1/${contentTypeToEndpoint[type]}/${id}/favorite`).then(
      (response) =>
        checkStatus({
          response,
          method: "DEL",
          endpoint: "universe/v1/:content_type/:content_id/favorite",
        }),
    ),
});
