import React from "react";

import { useAssetsConfig } from "../../contexts/AssetsConfig";

export default function FiveHundred() {
  const { assetsUrl } = useAssetsConfig();
  return (
    <div className="text-center flex flex-col justify-center items-center px-8 pt-8 mx-auto max-w-4xl">
      <h1 className="text-4xl mb-4">500</h1>
      <h2 className="text-2xl mb-4 leading-snug">
        Oops, something isn&apos;t right.
      </h2>
      <h2 className="text-lg mb-4">
        Don&apos;t worry - our engineers are looking into it!
      </h2>
      <img
        className="object-contain w-[226px] h-[394px]"
        src={`${assetsUrl}/web_public/images/Zapp_Von_Doubler_Broken.png`}
        alt="Zapp Von Doubler had an accident"
      />
      <div className="mt-4">
        Still having trouble? Contact{" "}
        <a className="font-bold underline" href="mailto:support@gonoodle.com">
          support@gonoodle.com.
        </a>
      </div>
    </div>
  );
}
